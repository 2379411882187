<template>
    <div>
        <report-header @senDataBackToParent="getValueFromChildComponent" @cancelFilterfn="cancelFn" @filterBrand="getValueBrand" @searchTextGlobal="getValueGlobal"></report-header>
        <report-table title="Tổng số công việc" :searchValue="valueSearch" v-if="showTable" :key="keyComponentBody"></report-table>
    </div>
</template>
<script>
import ReportHeader from './ReportHeader';
import ReportTable from './ReportTable';
export default {
    name: 'ReportHome',
    components: {
        ReportHeader,
        ReportTable
    },
    data () {
        return {
            valueSearch:'',
            valueBrand:'',
            keyComponentBody: 0,
            showTable: false
        }
    },
    methods: {
        getValueFromChildComponent(valueChildComponent){
            this.valueSearch = valueChildComponent
            this.keyComponentBody += 1
            this.showTable = true
        },
        getValueBrand(valueChildComponent){
            this.valueSearch = valueChildComponent
            this.showTable = true
        },
        getValueGlobal(valueChildComponent){
            this.valueSearch = valueChildComponent
            this.showTable = true
        },
        cancelFn(){
            this.showTable = false
            this.keyComponentBody +=1
        }
    }
}
</script>