<template>
    <div class="p-grid report">
        <div class="p-col-3">
            <div class="p-field">
                <label for="username1">Tìm kiếm</label>
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText type="text" v-model="search" placeholder="Search" />
                </span>
            </div>
        </div>
        <div class="p-col-2">
            <div class="p-field">
                <label for="username1">Brand</label>
                <Dropdown v-model="selectedBrand" :options="brands" optionLabel="name" optionValue="code" placeholder="" />
            </div>
        </div>
        <div class="p-col-2">
            <div class="p-field">
                <label for="username1">Thời gian</label>
                <span class="p-input-icon-right">
                    <Calendar id="timeBegin" v-model="timeBegin" :showIcon="true" placeholder="Từ ngày" dateFormat="dd/mm/yy" :minDate="minDateBegin" :maxDate="maxDateBegin" :manualInput="false" />
                </span>
            </div>
        </div>
        <div class="p-col-2">
            <div class="p-field">
                <label for="username1" style="color:#fff;">hidden</label>
                <span class="p-input-icon-right">
                    <Calendar id="timeEnd" v-model="timeEnd" :showIcon="true" placeholder="Đến ngày" dateFormat="dd/mm/yy" :minDate="minDateAfterSelected" :maxDate="maxDateEnd" :manualInput="false"/>
                </span>
            </div>
        </div>
            
        <div class="p-col-1">
            <div class="p-field">
                <label for="username1" style="color: #fff">hidden</label>
                <Button label="Huỷ lọc" @click="cancelFilter()"  class="p-button-text" style="border: 1px solid #E0E0E0; color:black"/>
            </div>
        </div>
        <div class="p-col-2">
            <div class="p-field">
                <label for="username1" style="color: #fff">hidden</label>
                <Button label="Áp dụng" @click="actionFilter()" class="p-button-raised p-button-text" style="background: #273990; color: #fff"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ReportHeader',
    data () {
        return {
            selectedBrand: '',
            brands: [
            ],
            search: '',
            timeBegin: null,
            timeEnd: null,
            minDateBegin:null,
            minDateEnd: null,
            maxDateBegin: null,
            maxDateEnd: null,
            maxDate:null,
            minDateAfterSelected: null,
            maxDateTimeBegin: null,
        }
    },
    watch: {
        search(newValue, oldValue){
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                if (this.timeEnd !=null && this.timeBegin != null) {
                    this.filterByGlobalText()
                }
            }
        },
        selectedBrand(newValue, oldValue){
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                if (this.timeEnd !=null && this.timeBegin != null) {
                    this.filterByBrand()
                }
            }
        },
        timeBegin(newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.minDateAfterSelected = this.timeBegin
                this.timeEnd = null
            }
        },
        timeEnd(newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                // this.maxDateBe = this.timeEnd
            }
        }
    },
    created(){
        this.addListBrand()
        //Set minDate and Maxdate when selected
        let today = new Date();
        let month = today.getMonth();
        let prevSixMonth = (month === 0) ? 11 : month -5;
        this.minDateBegin = new Date()
        this.minDateBegin.setMonth(prevSixMonth)
        this.maxDateEnd = new Date()
        this.maxDateBegin = new Date()
    },
    methods: {
        addListBrand(){
            let br = localStorage.getItem("BRAND");
            let brSplit = br.split(',');
            if (brSplit.length > 0) {
                let ob = {}
                for (let index = 0; index < brSplit.length; index++) {
                    let str = brSplit[index].replace(/\s/g, '');
                    ob = {
                        name: str, code:str
                    }
                    this.brands.push(ob);
                    ob = {}
                }
            }
        },
        cancelFilter(){
            this.selectedBrand = '',
            this.search= '',
            this.timeBegin = null
            this.timeEnd = null
            this.$emit('cancelFilterfn','cancel')
        },

        actionFilter(){
            if (this.timeBegin !== null && this.timeEnd !== null){
                let searchValueData = {
                    text: this.search,
                    brand: this.selectedBrand,
                    timeBegin: this.timeBegin !='' ? this.formatDate(this.timeBegin,'DATE') : '',
                    timeEnd: this.timeEnd !='' ? this.formatDate(this.timeEnd,'DATE') : '',
                }
                this.$emit('senDataBackToParent', searchValueData)
            } else {
                this.$toast.add({
                    severity:'error', 
                    summary: 'Error Message', 
                    detail:'You have to input search Time to filter' , life: 3000
                })
            }
        },
        filterByBrand(){
            if (this.selectedBrand != ''){
                let searchValueData = {
                    text: this.search,
                    brand: this.selectedBrand,
                    timeBegin: this.timeBegin !='' ? this.formatDate(this.timeBegin,'DATE') : '',
                    timeEnd: this.timeEnd !='' ? this.formatDate(this.timeEnd,'DATE') : '',
                }
                this.$emit('filterBrand', searchValueData)
            }
        },
        filterByGlobalText(){
            let searchValueData = {
                text: this.search,
                brand: this.selectedBrand,
                timeBegin: this.timeBegin !='' ? this.formatDate(this.timeBegin,'DATE') : '',
                timeEnd: this.timeEnd !='' ? this.formatDate(this.timeEnd,'DATE') : '',
            }
            this.$emit('searchTextGlobal', searchValueData)
        },
        formatDate(dateString, type){
            if (type == 'HOUR'){
                let date = new Date(dateString);
                // let dateLocal = date.toLocaleTimeString().split()
                return date.toLocaleTimeString();
            } else if (type == 'DATE') {
                const date = new Date(dateString);
                return date.getTime();
            } else if (type == 'MONTH') {
                const date = new Date(dateString);
                let time = date.toLocaleDateString('en-GB');
                let splitTime = time.split('/');
                // let month = date.getUTCMonth() + 1;
                // let year = date.getUTCFullYear();
                return splitTime[1] + '/' + splitTime[2];
            }
        },
    }
}
</script>
<style scoped>
.report .p-input-icon-right,
.report .p-input-icon-left{
    width: 100%;
}
.report .p-input-icon-right > .p-inputtext,
.report .p-input-icon-left > .p-inputtext,
.report .p-dropdown{
    padding-right: 0px;
    width: 100%;
}
.report .p-field{
    text-align: left;
}
.report label{
    display: block;
}
</style>