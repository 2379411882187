<template>
  <div class="home">
    <Toast /> 
    <ProgressSpinner v-if="isLoadingData"/>
    <div :class="[visibleFull ? 'sidebar-expand' : 'sidebar']" v-if="isLoadingData == false">
      <img src="./../assets/logo.png" alt="" v-if="visibleFull" @click="visibleFull = !visibleFull" width="150px" style="cursor:pointer">
      <img src="./../assets/child-logo.png" alt="" v-else @click="visibleFull = !visibleFull" width="40px" style="cursor:pointer">
      <Listbox v-model="formlink.select" :options="formlink.list" optionLabel="name" class="list-sidebar">
        <template #option="slotProps" >
          <div class="table-item" v-if="visibleFull">
            <p><img :src="slotProps.option.src" alt="" @click="visibleFull = !visibleFull" width="30px"><span>{{slotProps.option.name}}</span></p>
          </div>
          <div class="list-only-icon" v-else>
              <img :src="slotProps.option.src" alt="" width="30px">
          </div>
        </template>
      </Listbox>
    </div>
    <div :class="[visibleFull ? 'content' : 'content-expand']" v-if="isLoadingData == false">
      <div class="box-content">
          <div class="box-select p-grid">
            <div class="form-group-formlink p-col-12 p-sm-9 p-md-8 p-lg-7">
              <div class="box-select-calendar">
                <label for="">CALENDAR</label>
                <AutoComplete v-model="google.selectedGG" class="rm-outline calendar-input" :suggestions="google.filteredCalendar" @item-select="selectedGoogleCalendar($event.value)" @complete="searchGoogleCalendar($event)" :dropdown="true" field="name" forceSelection placeholder="Chọn lịch để xem">
                  <template #item="slotProps">
                      <div class="country-item">
                          <div class="p-ml-2">{{slotProps.item.name}}</div>
                      </div>
                  </template>
                </AutoComplete>
                <label for="" class="label-report" @click="clickReportButton()">BÁO CÁO</label>
              </div>
            </div>
            <div class="form-group-calendar p-col-12 p-sm-3 p-md-4 p-lg-5">
              <SplitButton :label="account.shortname" :model="items"></SplitButton>
            </div>
          </div>
      </div> 
      <!-- End box-content header name -->
      <!-- Table -->
      <div class="box-table" v-if="openReport == false">
        <DataTable :value="products" class="p-datatable-sm editable-cells-table p-datatable-gridlines p-datatable-striped" :filters="filters" :paginator="true" :rows="20"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[20,50,100]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" editMode="cell" columnResizeMode="fit" :key="formlink.selected" :expandedRows.sync="expandedRows" @row-expand="onRowExpand" @row-collapse="onRowCollapse" bodyClass="cssColumn"
        :selection.sync="selectedRowTable" selectionMode="single" :metaKeySelection="false">
          <!-- Add filter header -->
          <template #header>
            <div class="table-header p-grid nested-grid">
              <!-- box - btn  -->
              <div class="p-col-12 p-md-8 p-lg-8" style="margin-top:10px">
                <div class="btn p-grid">
                  <span for="" v-if="formlink.select" class="p-col-12 p-sm-12">{{formlink.select.name}}</span>
                  <Button label="Tạo mới" icon="pi pi-plus" @click="openFormInsertAndUpdate('insert')" v-show="formlink.select" class="p-col-4 p-sm-3 p-md-3 p-lg-3 p-xl-2"/>
                  <Button label="Cập nhật" icon="pi pi-check" @click="openFormInsertAndUpdate('update')" v-show="isShowButtonUpdateAndDelete"  class="p-col-4 p-sm-3 p-md-3 p-lg-3 p-xl-2"/>
                  <Button label="Duplicate" icon="pi pi-copy" @click="dupplicateData()" v-show="isShowButtonUpdateAndDelete" class="p-col-4 p-sm-3 p-md-3 p-lg-3 p-xl-2"/>
                  <Button label="Xoá" icon="pi pi-trash" @click="confirmDelete()" v-show="isShowButtonUpdateAndDelete" class="p-col-4 p-sm-3 p-md-3 p-lg-3 p-xl-2"/>
                </div>
              </div>
              <!-- Form search global -->
              <div class="p-col-12 p-md-4 p-lg-4">
                <span class="p-input-icon-left form-search-global">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global']"  class="rm-outline" placeholder="Global Search" />
                </span>
              </div>
            </div>
          </template>
        <template #empty>
            Data not found
        </template>
        <template #loading>
            Loading data. Please wait.
        </template>
        <!-- Display column -->
        <Column :expander="true" headerStyle="width: 3rem" />
        <Column v-for="col of columns" :field="col.field" :header="col.header" :key="selectedTable.name + ' + ' + col.field + col" filterMatchMode="contains" sortable @cell-edit-complete="onCellEditComplete" :headerClass="col.field">
          <!-- Convert data date -->
          <template #body="{data}" v-if="col.field.toUpperCase().includes('Edit Links')">
            <div v-if="role == 'Staff'">
              <a :href="data['Edit Links']" target="_blank" rel="noopener noreferrer" v-if="data['PIC'] == shortname">Edit</a>
            </div>
            <div v-else-if="role == 'Leader'">
              <div v-if="task != ''">
                <div v-if="members != ''">
                  <div v-for="member in members" :key='member.id'>
                      <a :href="data['Edit Links']" target="_blank" rel="noopener noreferrer" v-if="data['PIC'] == member">Edit</a>
                  </div>
                </div>
                <div v-else>
                    <a :href="data['Edit Links']" target="_blank" rel="noopener noreferrer">Edit</a>
                </div>
              </div>
              <div v-else-if="task == ''">
                <a :href="data['Edit Links']" target="_blank" rel="noopener noreferrer">Edit</a>
              </div>
            </div>
          </template>
          <template #body="{data}" v-else-if="col.field.toUpperCase().includes('TASK CỦA CHIẾN DỊCH*')">
            {{ Array.isArray(data['TASK CỦA CHIẾN DỊCH*']) ? data['TASK CỦA CHIẾN DỊCH*'].join() : data['TASK CỦA CHIẾN DỊCH*'] }}
          </template>
          <template #body="{data}" v-else>
            {{data[col.field]}}
          </template>
          <!-- Form Edit -->
          <template #filter>
            <InputText type="text" v-model="filters[col.field]" class="p-column-filter" placeholder="Search by name"/>
          </template>
        </Column>
        <!-- Expand column  -->
          <template #expansion="slotProps">
            <div class="p-field table-content">
              <table class="box-expand">
                <tr v-for="(slot,k) in slotProps.data" :key="k._id">
                  <th>{{k}}</th>
                  <td v-if="!k.toUpperCase().includes('TASK CỦA CHIẾN DỊCH*')">
                    <div v-if="Array.isArray(slot)">
                      <table class="box-expand">
                        <tr v-for="(sx,i) in slot" :key="'sx-'+i" class="item-row">
                           <table class="box-expand">
                            <tr v-for=" (s, x) in sx" :key="'x-'+x">
                              <th v-if="x != 'planId'" style="width: 10%">{{x}}</th>
                              <td v-if="x != 'planId'" style="width: 90%">{{s}}</td>
                            </tr>
                          </table>
                        </tr>
                      </table>
                    </div>
                    <div v-else>
                      {{slot}}
                    </div>
                  </td>
                  <td v-else>
                    {{ Array.isArray(slot) ? slot.join() : slot }}
                  </td>
                </tr>
              </table>
            </div>
        </template>  
      </DataTable>

      <!-- Show dialog -->
        <Dialog header="Form calendar" :visible="displayModel" :maximizable="true" :showHeader="false" closeOnEscape closable>
          <iframe :src="google.selectGG" style="border: 0" width="1200" height="800" frameborder="0" scrolling="no"></iframe>
          <template #footer>
            <Button label="Close" icon="pi pi-times" class="p-button-text" @click="closeModel()"/>
          </template>
        </Dialog>
        <!-- Show dialog Insert record-->
        <div v-if="formlink.select" class="box-insert">
          <!-- <dialog-form v-if="selectedType !=''" :formName="formlink.select.name" :actionType="selectedType" :isOpenFormInsert="isOpenFormInsert" :selectedRowTable="selectedRowTable" @openDialog="updateparent" @updateData="updateRowTable" @insertData="insertRowTable"></dialog-form> -->
          <ModalForm v-if="selectedType !=''" :formName="formlink.select.name" :actionTypesProp="selectedType" :isOpenFormInsert="isOpenFormInsert" :selectedRowTable="selectedRowTable" :roleUser='account.role' @openDialog="updateparent" @updateData="updateRowTable" @insertDataModal="insertRowTable"/>
        </div>
        <!-- Show dialog annouce success -->
        <div class="box-err">
          <Dialog :visible.sync="isOpenFormSuccess" :style="{width: '40vw'}">
            <p>Thực thi thành công</p>
            <Button label="Đồng ý" class="btn-confirm" @click="closeModel()"/>
          </Dialog>
        </div>
        <!-- Show dialog annouce error -->
          <div class="box-err">
            <Dialog :visible.sync="isOpenFormError" :style="{width: '40vw'}">
              <img src="./../assets/img_warning.svg" alt="Cảnh báo">
              <h5>Cảnh báo</h5>
              <p>Thực thi không thành công!</p>
              <Button label="Đồng ý" class="btn-error" @click="closeModel()"/>
            </Dialog>
          </div>
        <!-- Show dialog confirm -->
        <ConfirmDialog></ConfirmDialog>
        <!-- End block -->
        <!-- Show dialog Change password -->
        <div class="box-change-password">
          <Dialog header="Đổi mật khẩu" :visible.sync="showFormEditPassword" :style="{width: '30vw'}">
          <!-- <h3>Login form</h3> -->
            <div class="p-grid">
                <div class="p-col-12">
                    <div class="p-field">
                        <div class="p-inputgroup">
                            <InputText type="text" v-model="account.username" disabled/>
                        </div>
                    </div>
                    <div class="p-field">
                        <div class="p-inputgroup">
                            <span class="p-input-icon-right">
                                <Password v-model="account.oldPassword" toggleMask :feedback="false" placeholder="Old password"></Password>
                            </span>
                        </div>
                    </div>
                    <div class="p-field">
                        <div class="p-inputgroup">
                            <span class="p-input-icon-right">
                                <Password v-model="account.newPassword" toggleMask :feedback="false" placeholder="New password" ></Password>
                                <p class="p-mt-2">Suggestions</p>
                                  <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                      <li>At least one lowercase</li>
                                      <li>At least one uppercase</li>
                                      <li>At least one numeric</li>
                                      <li>Minimum 8 characters</li>
                                  </ul>
                            </span>
                        </div>
                    </div>
                    <div class="p-field">
                        <Button label="Lưu" class="btn-login" @click="updatePassword()"/>
                    </div>
                </div>
            </div>
          </Dialog>
        </div> 
        <!-- ENd box change password -->
      </div>

      <div class="box-report" v-if="openReport == true">
        <report-home></report-home>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ModalForm from './ModalForm';
import ReportHome from './reports/ReportHome';
export default {
  name: 'Homepage',
  components: {
    ModalForm,
    ReportHome
  },
  data() {
    return {
      account: {
        loginToken: localStorage.getItem('jwt'),
        fullname:'',
        shortname:'',
        role: '',
        task:'',
        members:'',
        username: localStorage.getItem('username'),
        oldPassword: '',
        newPassword: ''
      },
      formlink: {
        filteredFormLink: null,
        select: null,
        list: [],
      },
      google: {
        filteredCalendar:null,
        selectedGG: null,
        selectGG:null,
        listCalendar: []
      },
      filteredItemTable: null,
      listTableSelection:[],
      selectedTable:'',
      editingCellRows: {},
      products: [],
      filters: {},
      columns: [],
      items: [
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => {
                this.logOut()
            }
        },{
            label: 'Change password',
            icon: 'pi pi-user-edit',
            command: () => {
                this.showEditPass()
            }
        },
      ], // array split buton
      dataTable: '',
      data: '',
      expandedRows: [],
      productExpand:[],
      listDeletedItem: [],
      openReport: false,
      visibleFull:false,
      selectedRowTable: null,
      displayModel:false,
      isLoadingData: false,
      isShowButtonUpdateAndDelete:false,
      isOpenFormInsert: false,
      isLoadingCallAPIGetFormData: false,
      isOpenFormSuccess: false,
      isOpenFormError: false,
      blockedPanel: false,
      showFormEditPassword: false,
      selectedType:''
    }
  },
  watch: {
    'formlink.select': function(new_value){
      if (new_value) {  
        if (new_value.name) {
          this.isOpenFormInsert = false
          this.visibleFull = false
          let itemSelected = []
          this.selectedRowTable = null
          this.isShowButtonUpdateAndDelete = false
          itemSelected = this.dataTable[new_value.name]
          // filter row has been deleted
          if (this.listDeletedItem.length > 0){
            let idArr = this.listDeletedItem.map((record) => record.id);
            this.products = itemSelected.filter(item => {
              return idArr.indexOf(item._id) === -1
            })
          } else {
            this.products = itemSelected
          }
          // end
          this.editingCellRows = {}
          this.pushDataToFieldFilter(itemSelected); //push column to table
        }

        //Hidden report table
        this.openReport == true ? this.openReport = false : ''
      }
    },
    selectedRowTable: function(new_value){
      if (new_value) {
        if (this.account.role.toLowerCase().indexOf('leader') > -1 ){
          this.isShowButtonUpdateAndDelete = true
        } else {
          let str = 'PIC'
          let dataType = ''
          Object.keys(new_value).forEach(x => {
             let regexType = x.match(str);
             if (regexType) {
                this.types = {}
                dataType = regexType.input
            }
          })
      // Set data type
          if (this.account.shortname == new_value[dataType] || this.account.shortname == new_value[dataType]){
            this.isShowButtonUpdateAndDelete = true
          } else {
            this.isShowButtonUpdateAndDelete = false
          }
        }
      } else {
        this.isShowButtonUpdateAndDelete = false
      }
    }
  },
  created() {
      this.checkLogin() //Check has login
  },
  methods: {
    clickReportButton(){
      this.openReport =! this.openReport
      this.formlink.select =''
      this.products = []
      this.columns = []
    },
    showEditPass(){
      this.showFormEditPassword = true
    },
    updatePassword(){
      let regexPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
      if (regexPassword.test(this.account.newPassword) == false) {
        this.$toast.add({
          severity:'error', 
          summary: 'Error Message', 
          detail:'Điều kiện nhập vào chưa đủ', life: 3000
        })
      } else {
        let bodyFormData = new FormData();
          bodyFormData.append('loginToken', this.account.loginToken);
          bodyFormData.append('mode', "changePassword");
          bodyFormData.append('u', this.account.username);
          bodyFormData.append('p', this.account.oldPassword);
          bodyFormData.append('newP',this.account.newPassword);
        let self = this
        self.axios({
          method: "post",
          url: this.$urlAPI,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(response => {
          let data = response.data
          if (data.status == 'failed') {
            self.$toast.add({
              severity:'error', 
              summary: 'Error Message', 
              detail:'Mật khẩu cũ không chính xác', life: 3000
            })
          } else {
            self.showFormEditPassword = false
            self.$toast.add({
                severity:'success', 
                summary: 'Success Message', 
                detail:'Update password success', 
                life: 3000
            });
            localStorage.removeItem('jwt');
            localStorage.removeItem('secret');
            localStorage.removeItem('SHORT_NAME');
            localStorage.removeItem('FULL_NAME');
            localStorage.removeItem('username');
            self.$router.push({name:'Login'}) 
            // window.location.href = 'Login';
          }
        }).catch(err => 
            {
              self.$toast.add({
                  severity:'error', 
                  summary: 'Error Message', 
                  detail:'Err' + err, life: 3000
              })
            }
        ).finally(() => {})
      }
    },
    checkLogin(){
      if (localStorage.getItem("jwt") === null && localStorage.getItem("secret") === null && localStorage.getItem("username") === null) {
          this.$router.push({name: 'Login'})
      } else {
          this.getData()
      }
    },
    getData(){
      this.isLoadingData = true
      // Begin call api
      let bodyFormData = new FormData();
          bodyFormData.append('loginToken', this.account.loginToken);
          bodyFormData.append('mode', "requestData");
      let self = this
      self.axios({
        method: "post",
        url: this.$urlAPI,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(response => {
        let data = response.data
        console.log('datâ: ' + JSON.stringify(data))
        if (data.status == 'failed') {
          localStorage.removeItem('jwt');
          localStorage.removeItem('secret');
          localStorage.removeItem('SHORT_NAME');
          localStorage.removeItem('FULL_NAME');
          localStorage.removeItem('username');
          localStorage.removeItem('BRAND');
          this.$router.push({name:'Login'})
        } else {
            localStorage.setItem('FULL_NAME',data['FULL_NAME'])
            // localStorage.setItem('Brand Team',data['Brand Team'])
            localStorage.setItem('SHORT_NAME',data['SHORT_NAME'])
            localStorage.setItem('BRAND',data['BRAND'])
            this.account.role = data['ROLE']
            this.account.shortname = data['SHORT_NAME']
            this.account.fullname = data['FULL_NAME']
            this.account.task = data['TASK']
            this.data = data
            this.dataTable = data['Data Table']
            // this.pushListTable(this.dataTable)
            this.pushFormsLink(data['Form Link'])
            this.pushListCalendar(data.Calendars)
        }
      }).catch(err => 
      {
          this.$toast.add({
              severity:'error', 
              summary: 'Error Message', 
              detail:'Err' + err, life: 3000
          })
      }
      ).finally(() => this.isLoadingData = false)
    },
    pushFormsLink(itemFormLink){
      this.formlink.list = []
      if (itemFormLink) {
        Object.keys(itemFormLink).forEach(key => {
          let obFormLink = {
              name: key, 
              code: key,
              src: itemFormLink[key]
          }
          this.formlink.list.push(obFormLink)
        });
      }
    },
    pushListCalendar(itemCalendars){
      this.google.listCalendar = []
      if (itemCalendars) {
        Object.keys(itemCalendars).forEach(key => {
          let obCalendar = {
              name: key, 
              code: itemCalendars[key]
          }
          this.google.listCalendar.push(obCalendar)
        });
      }
    },
    openFormInsertAndUpdate(type){
        this.selectedType = type
        this.isOpenFormInsert = true
    },
    onRowExpand(event) {
      this.productExpand.push(event.data)
        // this.$toast.add({severity: 'info', summary: 'Product Expanded', detail: event.data, life: 3000});
    },
    onRowCollapse() {
      this.productExpand = []
        // this.$toast.add({severity: 'success', summary: 'Product Collapsed', detail: event.data, life: 3000});
    },
    selectedGoogleCalendar(newSelected){
      if (newSelected) {
        this.google.selectGG = newSelected.code
        this.displayModel = true
      }
    },
    checkObjectNotNull(obj){
      for (var key in obj) {
        if (obj[key].required == 'true' && obj[key].value == "")
            return false;
      }
      return true;
    },
    searchFormLink(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
              this.formlink.filteredFormLink = [...this.formlink.list];
          }
          else {
              this.formlink.filteredFormLink = this.formlink.list.filter((link) => {
                  return link.name.toLowerCase().startsWith(event.query.toLowerCase());
              });
          }
      }, 250);
    },
    searchGoogleCalendar(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
              this.google.filteredCalendar = [...this.google.listCalendar];
          }
          else {
              this.google.filteredCalendar = this.google.listCalendar.filter((googleCalendar) => {
                  return googleCalendar.name.toLowerCase().startsWith(event.query.toLowerCase());
              });
          }
      }, 250);
    },
    formatDate(dateString, type){
      if (type == 'HOUR'){
        let date = new Date(dateString);
        // let dateLocal = date.toLocaleTimeString().split()
        return date.toLocaleTimeString();
      } else if (type == 'DATE') {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB');
      }
    },
    closeModel(){
      this.displayModel = false
      this.openFormInsert = false
      this.isOpenFormSuccess = false
      this.isOpenFormError = false
    },
    logOut(){
        let self = this
        self.isLoadingData = true
        localStorage.removeItem('jwt');
        localStorage.removeItem('secret');
        localStorage.removeItem('SHORT_NAME');
        localStorage.removeItem('FULL_NAME');
        localStorage.removeItem('username');
        self.$toast.add({
            severity:'success', 
            summary: 'Success Message', 
            detail:'Logout success', 
            life: 3000
        });
        self.$router.push({name:'Login'})
    },
    pushDataToFieldFilter(items){
      //Loop all record, if has newcolumn add to data
      if (items.length > 0) {
        let self = this
        self.columns = []
        let abstractColumn = []
        for (let i = 0; i < items.length; i++){
          if (items[i] && items[i] !== null && items[i] != 'undefined') {
            Object.keys(items[i]).forEach(element => {
              if (element != '_id' && element !='Kế hoạch triển khai') {
                let ob_field = {
                  field: element,
                  header: element,
                }
                if (abstractColumn.includes(element) == false) {
                  abstractColumn.push(element)
                  self.columns.push(ob_field)
                }
              }
            })
          }
        }
      }
    },
    onCellEditComplete(event) {
      if (!this.editingCellRows[event.index]) {
          return;
      }
      const editingCellValue = this.editingCellRows[event.index][event.field];
      
      switch (event.field) {
          default:
              if (editingCellValue.trim().length > 0)
                  Vue.set(this.products, event.index, this.editingCellRows[event.index]);
              else
                  event.preventDefault();
          break;
      }
    },
    onCellEdit(newValue, props) {
      if (!this.editingCellRows[props.index]) {
          this.editingCellRows[props.index] = {...props.data};
      }
      this.editingCellRows[props.index][props.column.field] = newValue;
      this.isShowButtonUpdate = true
    },
    deleteRecordTable(){
      this.blockedPanel = true
      this.isLoadingData = true
      let bodyFormData = new FormData();
        bodyFormData.append('loginToken', this.account.loginToken);
        bodyFormData.append('mode', "delete");
        bodyFormData.append('collectionName', this.formlink.select.name);
        bodyFormData.append('records', JSON.stringify([{'id' : this.selectedRowTable._id, 'pic':this.account.shortname}]));
      let self = this
      self.axios({
        method: "post",
        url: this.$urlAPI,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(response => {
        if (response.data.status == 'success') {
          self.listDeletedItem.push({
            'table': self.formlink.select.name,
            'id':self.selectedRowTable._id
          })
          self.products = self.products.filter(val => val._id !== self.selectedRowTable._id );
          
          self.selectedRowTable = {}
          self.isOpenFormSuccess = true
        } else {
          self.isOpenFormError = true
        }
      }).catch(err => 
          this.$toast.add({
              severity:'error', 
              summary: 'Error Message', 
              detail:'Err' + err, life: 3000
          })
      ).finally(() => {
        self.isLoadingData = false
        self.blockedPanel = false
      })
    },
    discard() {
      this.$confirm.close();
    },
    confirmDelete(){
      this.$confirm.require({
        message: 'Bạn có chắc chắn muốn xoá bản ghi này ? ',
        header: 'Xác nhận',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Đồng ý',
        rejectLabel: 'Huỷ',
        accept: () => {
            //callback to execute when user confirms the action
            this.deleteRecordTable()
        },
        reject: () => {
            //callback to execute when user rejects the action
        }
      });
    },
    updateparent(variable){
      this.isOpenFormInsert = variable
      this.selectedType = ''
    },
    updateRowTable(ob){
      let index = this.products.findIndex(val => val._id ===this.selectedRowTable._id);
      this.$set(this.products, index, ob); 
      this.selectedRowTable=null
    },
    insertRowTable(dataInsertTable){
      // let formN = dataInsertTable.formNameData
      let data = dataInsertTable.data
      this.products.unshift(data)
      
    },
    dupplicateData(){
      // remove property _id + planID
      let dataRowSelected = JSON.parse(JSON.stringify(this.selectedRowTable));
      delete dataRowSelected._id
      if (typeof dataRowSelected == 'object' && dataRowSelected !=='null'){
        if ('Kế hoạch triển khai' in dataRowSelected) {
          for (let i = 0; i < dataRowSelected['Kế hoạch triển khai'].length; i++){
            delete dataRowSelected['Kế hoạch triển khai'][i].planId
          }
        }
        if ('NGƯỜI TẠO' in dataRowSelected) {
          let createdBy = JSON.parse(JSON.stringify(dataRowSelected['NGƯỜI TẠO']))
          delete dataRowSelected['NGƯỜI TẠO']
          dataRowSelected['SHORT_NAME'] = createdBy
        }
      }
      // 
      // Insert record
      this.blockedPanel = true
      let bodyFormData = new FormData();
          bodyFormData.append('loginToken', localStorage.getItem('jwt'));
          bodyFormData.append('mode', "insert");
          bodyFormData.append('collectionName', this.formlink.select.name);
          bodyFormData.append('records', JSON.stringify(dataRowSelected));
      let self = this
      self.axios({
          method: "post",
          url: this.$urlAPI,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then(response => {
          this.blockedPanel = false
          if (response.data.status == "success"){
            dataRowSelected._id = response.data._id
            if ('Kế hoạch triển khai' in dataRowSelected){
              for (let i = 0; i < dataRowSelected['Kế hoạch triển khai'].length; i++){
                dataRowSelected['Kế hoạch triển khai'][i].planId = response.data._id
              }
            }
            dataRowSelected['NGÀY TẠO'] = response.data['NGÀY TẠO']
            // Push notifications
            self.$toast.add({
                severity:'success', 
                summary: 'Success Message', 
                detail:'Dupplicated success', 
                life: 3000
            });
          }
      }).catch(err => 
          self.$toast.add({
              severity:'error', 
              summary: 'Error Message', 
              detail:'Err' + err, life: 3000
          })
      )
      .finally(() => {
        if ('SHORT_NAME' in dataRowSelected) {
          let createdBy = JSON.parse(JSON.stringify(dataRowSelected['SHORT_NAME']))
          delete dataRowSelected['SHORT_NAME']
          dataRowSelected['NGƯỜI TẠO'] = createdBy
        }
        this.products.unshift(dataRowSelected)
        // this.products = [dataRowSelected, ...this.products]
      })
      // console.log('dataRowSelected' + JSON.stringify(dataRowSelected))
    }
  }
}
</script>

<style>
.box-change-password .p-input-icon-right,
.box-change-password .p-password,
.box-change-password .p-password input{
  width: 100% !important ;
}
.box-change-password .p-inputgroup p,
.box-change-password .p-inputgroup ul li{
  text-align: left;
}
.p-datepicker table td{
  padding: 0px;
}
.box-update .p-dialog-footer{
  text-align: center;
}
.box-err .p-dialog-header{
  padding: 0px;
  display: flex;
  flex-flow: row-reverse;
  border-bottom: 0px;
}
.box-err .p-dialog-content h5{
  padding: 0px;
  margin-top: 0px;
  font-size: 20px;
}
.box-err .btn-err{
  margin-bottom: 40px;
}
.table-header ._id{
  display: none;
}
.table-header .btn {
  float: left;
  width: 100%;
  display: flex;
}
.table-header .btn span{
  display: flex;
  align-items: center;
  margin-right: 10px;
  width: fit-content;
}
.table-header .btn button{
  margin-right: 10px;
  background: #273990;
  width: fit-content;
}
.table-item p{
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
}
.table-item p span{
  position: absolute;
  top: 5px;
  margin-left: 10px;
}
.list-sidebar{
  border: none;
}
.list-sidebar .p-listbox-item{
  padding: 0.5rem 0.5rem !important;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight{
  border-right: 1px solid #D0001F;
  background: linear-gradient(90deg, rgba(253, 243, 244, 0.1) 0%, rgba(255, 255, 255, 0.1) 0.01%, rgba(214, 1, 27, 0.1) 68.75%);
  color: rgb(24, 24, 24);
  pointer-events:none;
}
.p-listbox .p-listbox-list .p-listbox-item:focus{
  box-shadow: none;
}
.list-sidebar ul li{
  text-align: left;
}
.sidebar{
  width: 4%;
  display: block;
  float: left;
  box-shadow: 1px 1px 1px #c1b6b6;
}
.sidebar-expand{
  width: 19%;
  display: block;
  float: left;
}
.content{
  display: block;
  float: left;
  width: 80%;
  margin-left: 1%;
}
.content-expand{
  display: block;
  float: left;
  width: 95%;
  margin-left: 1%;
}
.row-user{
  width: 100%;
  display: block;
  text-align: right;
}
.row-selection {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.box-select .form-group-formlink{
  display: block;
  float: left;
  text-align: left;
}
.box-select .form-group-formlink label{
  float: left;
  font-weight: 600;
}
.box-select .form-group-formlink .label-report{
  float: right;
  margin-left: 10px;
  cursor: pointer;
}
.box-select .form-group-calendar{
  display: block;
  float: right;
  text-align: right;
}
.box-select .form-group-calendar label{
  float: right;
}
.box-select-calendar{
  display: inline-block;
  margin-left: 15px;
}
.box-select-calendar label{
  display: inline-block;
  margin-top: 7px;
  margin-right: 10px;
}
.p-datatable-wrapper{
  display: block;
  overflow: auto;
}
.p-datatable table{
  width: unset !important;
}
.rm-outline input{
  box-shadow:none !important;
}
.calendar-input input{
  font-style: italic;
  border-right:0px;
}
.calendar-input input:focus{
  border-color: #ced4da !important;
}
.calendar-input button {
  background: transparent;
  color: #000000;
  border-left: 0px;
  border-color: #ced4da;
}
.calendar-input button:hover,
.calendar-input button:focus {
  background: transparent !important;
  color: #000 !important;
  border-color: #ced4da !important;
  box-shadow: none;
}
.form-group-formlink input{
  box-shadow:none !important;
}
/* .p-datatable-header{
  display: flex;
  position: relative;
}
.list-table{
  margin-right: 15px;
}*/
.table-header{
  text-align: right;
}
.p-column-filter{
  width: 150px;
}
.icon-hamberger{
  padding: 6px 10px;
  background: #007bff;
  border-radius: 4px;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
}
.form-group-calendar .box{
  display: inline-block;
    margin-right: 20px;
}

.btn-update{
  margin-left: 10px !important;
}

.box-expand td,
.box-expand th{
   border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item{
  text-align: left !important;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td{
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 450px !important;
}
.btn-confirm{
  background: #263A91;
  border: 3px;
  padding: 1rem 1.5rem;
}
.form-insert label{
  display: block !important;
  text-align: left;
}
.form-insert input{
  text-align: left;
}
.form-insert .dropdown-insert,
.form-insert .text-insert,
.form-insert .calendar-insert,
.form-insert .number-insert{
  float: left;
  width: 100%;
}
.form-insert .dropdown-insert ul li{
  text-align: left;
}
.box-insert span,
.box-update span{
  text-align: left;
}
.box-insert .p-dialog-footer{
  text-align: center;
}
.box-insert .btn-insertForm-cancel{
  color: #000;
  border-color: #F1F1F5;
  background: transparent;
  padding: 0.6rem 3rem;
}
.box-insert .btn-insertForm-save{
  color: #fff;
  background: #273990;
  padding: 0.6rem 3rem;
}
.box-expand .item-row{
  margin-bottom: 10px; 
  display: block;
}
.box-expand .item-row:nth-child(odd) {
  background: #F1F1F5;
}
@media only screen and (max-width: 576px) {
  .sidebar{
  width: 15%;
  display: block;
  float: left;
  box-shadow: 1px 1px 1px #c1b6b6;
}
.sidebar-expand{
  width: 99%;
  display: block;
  float: left;
}
.content{
  display: none;
  float: left;
  width: 1%;
  margin-left: 1%;
}
.content-expand{
  display: block;
  float: left;
  width: 84%;
  margin-left: 1%;
}
  .box-select{
    margin-left: 0px !important;
    width: 100%;
  }
  .form-group-formlink,
  .form-group-formlink span,
  .form-group-formlink span input{
    width: 100%;
  }
  .form-group-calendar,
  .form-group-calendar span,
  .form-group-calendar span input{
    width: 100% !important;

  }
  .form-group-calendar span .p-button.p-button-icon-only{
    padding: 0.5rem 0.75rem !important;
  }
  .form-group-calendar label{
    float: left !important;
  }
  .p-datatable .p-datatable-header{
    display: block;
  }
  .table-header{
    position: relative !important;
    margin-top: 10px;
  }
  .list-table{
    width: 100%;
    margin-bottom: 20px;
  }
  .table-header span,
  .table-header span input{
    width: 100%;
  }
  .box-select .form-group-calendar{
    float: right;
    text-align: right;
    display: flex;
    flex-flow: column-reverse;
  }
  .box-select .form-group-calendar .box{
    margin-right: 0px;
  }
  .box-select .form-group-calendar .p-splitbutton{
    margin-bottom: 10px;
  }
  .box-select .form-group-calendar .p-button.p-button-icon-only{
    padding: 8px 12px !important;
  }
  .p-dialog{
    overflow: auto;
  }
  .table-header .btn span{
    font-size: 12px;
    margin-right:0px;
    width: inherit;
  }
  .table-header .btn{
    margin: 0 auto;
  }
  .table-header .btn button{
    margin-right: 10px;
  }
  .box-select .form-group-formlink .label-report{
    float: left;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
   .sidebar{
    width: 7%;
    display: block;
    float: left;
    box-shadow: 1px 1px 1px #c1b6b6;
  }
  .sidebar-expand{
    width: 30%;
    display: block;
    float: left;
  }
  .content{
    display: block;
    float: left;
    width: 69%;
    margin-left: 1%;
  }
  .content-expand{
    display: block;
    float: left;
    width: 92%;
    margin-left: 1%;
  }
  .form-search-global{
    float: left;
  }
}
@media only screen and (min-width: 577px) {
  .table-header{
    margin-right: 15px;
    margin-top:10px !important;
  }
  .box-select .form-group-calendar .box label{
    float: left;
  }
  .table-header .btn button{
    margin-right: 5px;
  }
  
}
@media only screen and (min-width: 769px) {
  .sidebar{
    width: 7%;
    display: block;
    float: left;
    box-shadow: 1px 1px 1px #c1b6b6;
  }
  .sidebar-expand{
    width: 25%;
    display: block;
    float: left;
  }
  .content{
    display: block;
    float: left;
    width: 74%;
    margin-left: 1%;
  }
  .content-expand{
    display: block;
    float: left;
    width: 92%;
    margin-left: 1%;
  }
  .table-header{
    margin-top:10px !important;
  }
}
@media only screen and (min-width: 1024px) {
  .sidebar{
  width: 4%;
  display: block;
  float: left;
  box-shadow: 1px 1px 1px #c1b6b6;
}
.sidebar-expand{
  width: 19%;
  display: block;
  float: left;
}
.content{
  display: block;
  float: left;
  width: 80%;
  margin-left: 1%;
}
.content-expand{
  display: block;
  float: left;
  width: 95%;
  margin-left: 1%;
}
}
</style>