<template>
    <div class="modal-form">
        <div v-if="openInsertTask == false">
            <ProgressSpinner v-if="isLoadingData"/>
            <Dialog :header="formName" :visible.sync="openDialog" :maximizable="true" :showHeader="true" :modal="true" :closeOnEscape="false" :closable="false" :style="{width: '70vw'}" :key="formName">
                <div class="p-formgrid p-grid form-insert" >
                    <div class="p-field p-col-6 " v-for="val, index in listDataForm" :key="'creatForm-'+ index">
                        <label for="basic" v-if="!val.nameKey.toUpperCase().includes('SUB_BRAND') && !val.nameKey.toUpperCase().includes('SUB BRAND') && !val.nameKey.toUpperCase().includes('SUB-BRAND') && !val.nameKey.toUpperCase().includes('LOẠI-CONTENT') && !val.nameKey.toUpperCase().includes('LOẠI_CONTENT') && !val.nameKey.toUpperCase().includes('LOẠI CONTENT') && !val.nameKey.toUpperCase().includes('KÊNH*') && !val.nameKey.toUpperCase().includes('PIC')">{{val.nameKey}}</label>
                        <Dropdown v-model="val.value" :filter="true" class="dropdown-insert" :options="val.data" v-if="val.type == 'ARRAY' && val.nameKey.toUpperCase().includes('BRAND*')" optionLabel="name" @change="onChangeBrand($event)"/>
                        <Dropdown v-model="val.value" :filter="true" class="dropdown-insert" :options="val.data" v-else-if="val.type == 'ARRAY' &&val.nameKey.toUpperCase().includes('TRẠNG THÁI*')" optionLabel="name"/>
                        <Dropdown v-model="val.value" :filter="true" class="dropdown-insert" :options="val.data" v-else-if="val.type == 'ARRAY'" optionLabel="name"/>
                        <label for="basic" v-if="val.nameKey.toUpperCase().includes('SUB_BRAND') || val.nameKey.toUpperCase().includes('SUB BRAND') || val.nameKey.toUpperCase().includes('SUB-BRAND')" >{{val.nameKey.toUpperCase()}}</label>
                        <Dropdown v-model="val.value" :filter="true" class="dropdown-insert" :options="sub_brand" v-if="val.nameKey.toUpperCase().includes('SUB_BRAND') || val.nameKey.toUpperCase().includes('SUB BRAND') || val.nameKey.toUpperCase().includes('SUB-BRAND')" optionLabel="name"/>
                        <label for="basic" v-if="val.nameKey.toUpperCase().includes('KÊNH*') || val.nameKey.toUpperCase().includes('KENH') || val.nameKey.toUpperCase().includes('KÊNH')" >{{val.nameKey}}</label>
                        <Dropdown v-model="val.value" :filter="true" class="dropdown-insert" :options="sub_listChanel" v-if="val.nameKey.toUpperCase().includes('KÊNH*') || val.nameKey.toUpperCase().includes('KENH') || val.nameKey.toUpperCase().includes('KÊNH')"  optionLabel="name"/>

                        <label for="basic" v-if="val.nameKey.toUpperCase().includes('PIC*') || val.nameKey.toUpperCase().includes('PIC')">{{val.nameKey}}</label>
                        <Dropdown v-model="val.value" :filter="true" class="dropdown-insert" :options="sub_listPic" v-if="val.nameKey.toUpperCase().includes('PIC*') || val.nameKey.toUpperCase().includes('PIC')" optionLabel="name"/>

                        <label for="basic" v-if="val.nameKey.toUpperCase().includes('LOẠI CONTENT') || val.nameKey.toUpperCase().includes('LOẠI_CONTENT') || val.nameKey.toUpperCase().includes('LOẠI-CONTENT')" >{{val.nameKey}}</label>
                        <Dropdown v-model="val.value" :filter="true" class="dropdown-insert" :options="typeContent" v-if="val.nameKey.toUpperCase().includes('LOẠI CONTENT') || val.nameKey.toUpperCase().includes('LOẠI-CONTENT') || val.nameKey.toUpperCase().includes('LOẠI_CONTENT')" optionLabel="name"/>
                        <InputText type="number" class="text-insert" min='0' v-model="val.value" v-if="val.data == 'NUMBER'" pattern="^(\d+[0-9])"/>
                        <InputText type="text" class="text-insert" v-model="val.value" v-if="val.data=='STRING' || val.data=='LINK' || val.data=='TEXT'"/>
                        <Calendar v-model="val.value" class="calendar-insert" v-if="val.data=='DATE' && !val.nameKey.toUpperCase().includes('NGÀY BĂT ĐẦU') && minDateBegin ==null" dateFormat="dd/mm/yy" placeholder="dd/mm/yy"/>
                        <Calendar v-model="val.value" class="calendar-insert" v-if="val.data=='DATE' && val.nameKey.toUpperCase().includes('NGÀY BĂT ĐẦU')" dateFormat="dd/mm/yy" placeholder="dd/mm/yy" @hide="onChangeDate(val.nameKey)"/>
                        <Calendar v-model="val.value" class="calendar-insert" v-if="val.data=='DATE' && !val.nameKey.toUpperCase().includes('NGÀY BĂT ĐẦU') && minDateBegin !=null" dateFormat="dd/mm/yy" placeholder="dd/mm/yy" :minDate="minDateBegin" :disabled="minDateBegin ==null ? true:false"/>
                        <Calendar v-model="val.value" class="calendar-insert" :timeOnly="true" hourFormat="24" v-if="val.data=='HOUR'" placeholder="00:00"/>
                    </div>
                </div>
                <div class="p-grid" v-if="listCheckCampaign">
                    <label for="basic" class="p-col-12" style="text-align: left">{{listCheckCampaign.nameKey}}</label>
                    <div v-for="check of listCheckCampaign.data" :key="check.code" class="p-field-checkbox p-col-4">
                        <Checkbox :id="check.key" name="check" :value="check" v-model="checkedCampain" />
                        <label :for="check.code">{{check.name}}</label>
                    </div>
                </div>
                <div class="tableplan" v-if="dataTablePlan !='' && dataTablePlan != null">
                    <table>
                        <thead class="p-datatable-thead">
                            <th class="action-table">
                                <i class="pi pi-plus"></i>
                            </th>
                            <th class="action-table">
                                INSERT TASK
                            </th>
                            <th v-for="(valHead, indexHead) in abstractDataTable" :key="indexHead">
                                {{valHead.key}}
                            </th>
                        </thead>
                        <tbody class="p-datatable-tbody">
                            <template v-for="(table, index) in tableTest">
                                <tr :key="index">
                                    <td class="action-table" @click="toggle(index)">
                                        <img src="./../assets/arrow-right.svg" alt="">
                                    </td>
                                    <td style="text-align: center"><Button label="" icon="pi pi-plus" :class="[table.hiddenClass ? 'hiddenButton' : '']" iconPos="right" @click="formInsertTask(index)" v-if="actionTypesProp=='update'"/></td>
                                    <td v-for="(row,i) in table.data" :key="i">
                                        <Dropdown v-model="row.value" class="dropdown-insert" :options="row.data" v-if="row.type == 'ARRAY' && row.key =='CÔNG VIỆC*'" optionLabel="name" @change="onChangeJob($event, index)"/>
                                        <Dropdown v-model="row.value" class="dropdown-insert" :options="listTypesPlan[index]" v-if="Array.isArray(listTypes) && row.key=='PHÂN LOẠI'" optionLabel="name"/>
                                        <Dropdown v-model="row.value" class="dropdown-insert" :options="row.data" v-else-if="row.type == 'ARRAY' && row.key !='CÔNG VIỆC*' && row.key !='PHÂN LOẠI' && row.key =='DUYỆT' && roleUser.toLowerCase().includes('plan_approval')" optionLabel="name"/>
                                        <Dropdown v-model="row.value" class="dropdown-insert" :options="row.data" v-else-if="row.type == 'ARRAY' && row.key !='CÔNG VIỆC*' && row.key !='PHÂN LOẠI' && row.key =='DUYỆT' && !roleUser.toLowerCase().includes('plan_approval')" optionLabel="name" disabled/>
                                        <Dropdown v-model="row.value" class="dropdown-insert" :options="row.data" v-if="row.type == 'ARRAY' && row.key !='CÔNG VIỆC*' && row.key !='PHÂN LOẠI' && row.key !='DUYỆT'" optionLabel="name"/>
                                        <InputText type="text" class="text-insert" v-model="row.value" v-if="row.type=='STRING' && row.key=='GHI CHÚ' && roleUser.toLowerCase().includes('plan_approval') || row.type=='LINK' && row.key=='GHI CHÚ' && roleUser.toLowerCase().includes('plan_approval') || row.type=='TEXT' && row.key=='GHI CHÚ' && roleUser.toLowerCase().includes('plan_approval')"/>
                                        <InputText type="text" class="text-insert" v-model="row.value" v-else-if="row.type=='STRING' && row.key=='GHI CHÚ' && !roleUser.toLowerCase().includes('plan_approval') || row.type=='LINK' && row.key=='GHI CHÚ' && !roleUser.toLowerCase().includes('plan_approval') || row.type=='TEXT' && row.key=='GHI CHÚ' && !roleUser.toLowerCase().includes('plan_approval')" disabled/>
                                        <InputText type="text" class="text-insert" v-model="row.value" v-else-if="row.type=='STRING' || row.type=='LINK' || row.type=='TEXT' "/>
                                        <InputText type="number" class="text-insert" min='0' v-model="row.value" v-if="row.type=='NUMBER'" pattern="^(\d+[0-9])"/>
                                    </td>
                                    <td class="action-table" @click="removeItem(index)" v-if="roleUser.includes('Plan_approval')"><i class="pi pi-trash" style="color:red;"></i></td>
                                </tr>
                                <!-- <tr :key="'btn-addPlan-' + index">
                                    
                                </tr> -->
                                <!-- Show expand item -->
                                <tr v-if="opened.includes(index)" :key="'show-'+index">
                                    <td :colspan="table.length + 1">
                                        <div class="p-field table-content">
                                            <!-- {{slotProps.data}} -->
                                            <table class="box-expand">
                                                <tr v-for="(slot,k) in table" :key="k.id">
                                                <th v-if="slot.key != '_id'">{{slot.key}}</th>
                                                <td v-if="typeof slot.value ==='object' && slot.value !=='null'">{{slot.value.name}}</td>
                                                <td v-else-if="slot.key != '_id'">{{slot.value}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody> 
                    </table>
                    <Button label="Add" icon="pi pi-plus" class="btn-add" iconPos="right" @click="addRowTable()"/>
                </div>
                <template #footer>
                    <Button label="Huỷ" class="btn-insertForm-cancel" @click="closeModal()" :disabled="blockedUser"/>
                    <Button label="Tạo mới" class="btn-insertForm-save" @click="insertRecord('insert')" v-if="actionTypesProp == 'insert'" :disabled="blockedUser"/>
                    <Button label="Cập nhật" class="btn-insertForm-save" @click="insertRecord('update')" v-else :disabled="blockedUser"/>
                </template>
            </Dialog>
        </div>
        <insert-task v-if="openInsertTask" :formName="selectedJob" :formPlan="selectedRowTable" :isOpenFormInsertTask="openInsertTask" @openInsertTaskModal="openInsertTaskModal" @insertData="insertRowTable"></insert-task>
    </div>
</template>
<script>
import InsertTask from './InsertTask.vue'
export default {
    name: 'ModalForm',
    components: {
        InsertTask
    },
    props:['formName','actionTypesProp','isOpenFormInsert','selectedRowTable', 'roleUser'],
    data() {
        return {
            listDataForm:[],
            listSub_brand:[],
            listTypeContent:null,
            typeContent:[],
            dataTablePlan:[],
            sub_brand:[],
            abstractDataTable: [],
            tableTest: [],
            listTypesPlan:[],
            opened: [],
            listTypes:[{
                name: 'Không có dữ liệu',
                code: 'Không có dữ liệu'
            }],
            listChanel: [],
            sub_listChanel: [],
            listPIC: [],
            sub_listPic: [],
            listCheckCampaign: null,
            checkedCampain: [],
            selectedJob: null,
            openDialog: this.isOpenFormInsert,
            blockedUser: false,
            isLoadingData: false,
            afterConvertData: null,
            minDateBegin: null,
            openInsertTask: false,
            hiddenClass:true,
            errorApproved: false
        }
    },
    watch: {
    },
    created(){
        this.getData()
    },
    methods: {
        getData(){
            this.listDataForm=[]
            this.listSub_brand=[]
            this.dataTablePlan=[]
            this.sub_brand=[]
            this.blockedUser = true
            this.$toast.add({
                severity:'info', 
                summary: 'Informations', 
                detail:'Data is loading, please wait for minute!', life: 3000
            })
            if(this.actionTypesProp == 'insert'){
                let bodyFormData = new FormData();
                    bodyFormData.append('loginToken', localStorage.getItem('jwt'));
                    bodyFormData.append('mode', "requestForm");
                    bodyFormData.append('formName', this.formName);
                let self = this
                    self.axios({
                    method: "post",
                    url: this.$urlAPI,
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then(response => {
                let dataForm = response.data
                this.dataTablePlan = null
                if (dataForm) {
                    for (let key in dataForm) {
                        let regexPlan = key.match('triển khai');
                        if (regexPlan) {
                            let keyPlan = regexPlan.input
                            this.dataTablePlan = response.data[keyPlan]
                            this.abstractTable(this.dataTablePlan)
                        }
                        let arr = {
                            data: '',
                            required: '',
                            nameKey: key,
                            type: ''
                        }
                        if (!key.toUpperCase().includes('TASK CỦA CHIẾN DỊCH*')) {
                            if (key.includes('*')) {
                                if (Array.isArray(dataForm[key])) {
                                    let arrF = []
                                    if (dataForm[key].length > 0) {
                                        for (let i = 0; i< dataForm[key].length; i++) {
                                            let object = {
                                                name: dataForm[key][i],
                                                code: dataForm[key][i]
                                            }
                                            arrF.push(object)
                                        }
                                        arr = {
                                            data: arrF,
                                            required: 'true',
                                            nameKey: key,
                                            type:"ARRAY",
                                            value:''
                                        }
                                    } else {
                                        let object = {
                                            name: "Không có dữ liệu",
                                            code: "Không có dữ liệu"
                                        }
                                        arrF.push(object)
                                        arr = {
                                            data: arrF,
                                            required: 'true',
                                            nameKey: key,
                                            type:"ARRAY",
                                            value:''
                                        }
                                    }
                                } else if (typeof dataForm[key] === 'object' && dataForm[key] !== 'null')  {
                                    if (key.toUpperCase().includes('KÊNH*') || key.toUpperCase().includes('KÊNH') || key.toUpperCase().includes('KENH')) {
                                        this.listChanel = dataForm[key]
                                    } else if (key.toUpperCase().includes('PIC*') || key.toUpperCase().includes('PIC')) {
                                        this.listPIC = dataForm[key]
                                    }
                                } else {
                                    arr = {
                                        data: dataForm[key] ? dataForm[key] : 'STRING',
                                        required: 'true',
                                        nameKey: key,
                                        value:''
                                    }  
                                } // End check KÊNH*
                            } else if (key == 'SUB_BRAND' || key == 'SUB BRAND' || key == 'SUB-BRAND') {
                                this.listSub_brand = dataForm[key]
                            } else if (key == 'LOẠI CONTENT' || key == 'LOẠI_CONTENT' || key == 'LOẠI-CONTENT') {
                                this.listTypeContent = dataForm[key]
                            } else {
                                if (Array.isArray(dataForm[key])) {
                                    let arrF = []
                                    if (dataForm[key].length > 0) {
                                        for (let i = 0; i< dataForm[key].length; i++) {
                                            let object = {
                                                name: dataForm[key][i],
                                                code: dataForm[key][i]
                                            }
                                            arrF.push(object)
                                        }
                                        arr = {
                                            data: arrF,
                                            required: 'false',
                                            nameKey: key,
                                            type:"ARRAY",
                                            value:''
                                        }
                                    } else {
                                        let object = {
                                            name: "Không có dữ liệu",
                                            code: "Không có dữ liệu"
                                        }
                                        arrF.push(object)
                                        arr = {
                                            data: arrF,
                                            required: 'false',
                                            nameKey: key,
                                            type:"ARRAY",
                                            value:''
                                        }
                                    }
                                }  else if (typeof dataForm[key] === 'object' && dataForm[key] !== 'null')  {
                                    if (key.toUpperCase().includes('KÊNH*') || key.toUpperCase().includes('KÊNH') || key.toUpperCase().includes('KENH')) {
                                        this.listChanel = dataForm[key]
                                    } else if (key.toUpperCase().includes('PIC*') || key.toUpperCase().includes('PIC')) {
                                        this.listPIC = dataForm[key]
                                    }
                               } else {
                                    arr = {
                                        data: dataForm[key],
                                        required: 'false',
                                        nameKey: key,
                                        value:''
                                    }  
                                }
                            }
                            this.listDataForm.push(arr)
                        } else {
                            if (Array.isArray(dataForm[key])) {
                                let arrF = []
                                if (dataForm[key].length > 0) {
                                    for (let i = 0; i< dataForm[key].length; i++) {
                                        let object = {
                                            name: dataForm[key][i],
                                            code: dataForm[key][i]
                                        }
                                        //check if array has exist item. Not push to array
                                        arrF.push(object)
                                    }
                                    arr = {
                                        data: arrF,
                                        required: 'true',
                                        nameKey: key,
                                        type:"ARRAY",
                                        value:''
                                    }
                                } else {
                                    let object = {
                                        name: "Không có dữ liệu",
                                        code: "Không có dữ liệu"
                                    }
                                    arrF.push(object)
                                    arr = {
                                        data: arrF,
                                        required: 'true',
                                        nameKey: key,
                                        type:"ARRAY",
                                        value:''
                                    }
                                }
                            }
                            this.listCheckCampaign = arr
                        }
                        
                        // this.listDataForm[key].required = 
                    }
                }
                }).catch(err => 
                    this.$toast.add({
                        severity:'error', 
                        summary: 'Error Message', 
                        detail:'Err' + err, life: 3000
                    })
                ).finally(() => {
                    this.blockedUser = false
                    this.$toast.add({
                        severity:'success', 
                        summary: 'Informations', 
                        detail:'Data has loaded!', life: 3000
                    })
                })
                // Begin update
            } else if(this.actionTypesProp == 'update'){
                let bodyFormData = new FormData();
                    bodyFormData.append('loginToken', localStorage.getItem('jwt'));
                    bodyFormData.append('mode', "requestForm");
                    bodyFormData.append('formName', this.formName);
                let self = this
                self.axios({
                    method: "post",
                    url: this.$urlAPI,
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(response => {
                let dataForm = response.data
                if (dataForm) {
                    for (let key in dataForm) {
                        let regexPlan = key.match('triển khai');
                        if (regexPlan) {
                            let keyPlan = regexPlan.input
                            this.dataTablePlan = response.data[keyPlan]
                            this.abstractTable(this.dataTablePlan, this.selectedRowTable[keyPlan])
                        }
                        let arr = {
                            data: '',
                            required: '',
                            nameKey: key,
                            type: ''
                        }
                        if (!key.toUpperCase().includes('TASK CỦA CHIẾN DỊCH*')) {
                            if (key.includes('*')) {
                                if (Array.isArray(dataForm[key])) {
                                    let arrF = []
                                    if (dataForm[key].length > 0) {
                                        for (let i = 0; i< dataForm[key].length; i++) {
                                            let object = {
                                                name: dataForm[key][i],
                                                code: dataForm[key][i]
                                            }
                                            arrF.push(object)
                                        }
                                        arr = {
                                            data: arrF,
                                            required: 'true',
                                            nameKey: key,
                                            type:"ARRAY",
                                            value: {
                                                name: self.selectedRowTable[key] ? self.selectedRowTable[key] : '',
                                                code: self.selectedRowTable[key] ? self.selectedRowTable[key] : ''
                                            }
                                        }
                                    } else {
                                        let object = {
                                            name: "Không có dữ liệu",
                                            code: "Không có dữ liệu"
                                        }
                                        arrF.push(object)
                                        arr = {
                                            data: arrF,
                                            required: 'true',
                                            nameKey: key,
                                            type:"ARRAY",
                                            value: {
                                                name: self.selectedRowTable[key] ? self.selectedRowTable[key] : '',
                                                code: self.selectedRowTable[key] ? self.selectedRowTable[key] : ''
                                            }
                                        }
                                    }
                                } else if (typeof dataForm[key] === 'object' && dataForm[key] !== 'null')  {
                                    if (key.toUpperCase().includes('KÊNH*') || key.toUpperCase().includes('KÊNH') || key.toUpperCase().includes('KENH')) {
                                        this.listChanel = dataForm[key]
                                        let dataOfBrand = self.selectedRowTable["BRAND*"] // get data of brand
                                        let arrSubChanel = []
                                        this.sub_listChanel = null
                                        for (const keyChanel in this.listChanel) {  //loop all data subband
                                            if (keyChanel == dataOfBrand) {
                                                for (let i = 0; i< this.listChanel[keyChanel].length; i++) {
                                                let object = {
                                                    name: this.listChanel[keyChanel][i],
                                                    code: this.listChanel[keyChanel][i]
                                                }
                                                arrSubChanel.push(object)
                                                }
                                            }
                                        }
                                        this.sub_listChanel = arrSubChanel
                                        // Set data sub brand if exist
                                        arr = {
                                            nameKey: key,
                                            value: {
                                                name: self.selectedRowTable[key] ? self.selectedRowTable[key] : '',
                                                code: self.selectedRowTable[key] ? self.selectedRowTable[key] : ''
                                            }
                                        }  
                                    } else if (key.toUpperCase().includes('PIC*') || key.toUpperCase().includes('PIC')) {
                                        this.listPIC = dataForm[key]
                                        let dataOfBrand = self.selectedRowTable["BRAND*"] // get data of brand
                                        let arrSubPic = []
                                        this.sub_listPic = null
                                        for (const keyPic in this.listPIC) {  //loop all data subband
                                            if (keyPic == dataOfBrand) {
                                                for (let i = 0; i< this.listPIC[keyPic].length; i++) {
                                                let object = {
                                                    name: this.listPIC[keyPic][i],
                                                    code: this.listPIC[keyPic][i]
                                                }
                                                arrSubPic.push(object)
                                                }
                                            }
                                        }
                                        this.sub_listPic = arrSubPic
                                        // Set data sub brand if exist
                                        arr = {
                                            nameKey: key,
                                            value: {
                                                name: self.selectedRowTable[key] ? self.selectedRowTable[key] : '',
                                                code: self.selectedRowTable[key] ? self.selectedRowTable[key] : ''
                                            }
                                        }  
                                    }
                                } else {
                                    arr = {
                                        data: dataForm[key] ? dataForm[key] : 'STRING',
                                        required: 'true',
                                        nameKey: key,
                                        value: self.selectedRowTable[key] ? self.selectedRowTable[key] : ''
                                    }  
                                } // End check required array
                            } else if (key == 'SUB_BRAND' || key == 'SUB BRAND' || key == 'SUB-BRAND') {
                                this.listSub_brand = dataForm[key] //Push object data to listSub_brand
                                let dataOfBrand = self.selectedRowTable["BRAND*"] // get data of brand
                                let arrSubBrand = []
                                this.sub_brand = null
                                for (const keySub in this.listSub_brand) {  //loop all data subband
                                    if (keySub == dataOfBrand) {
                                        for (let i = 0; i< this.listSub_brand[keySub].length; i++) {
                                        let object = {
                                            name: this.listSub_brand[keySub][i],
                                            code: this.listSub_brand[keySub][i]
                                        }
                                        arrSubBrand.push(object)
                                        }
                                    }
                                }
                                this.sub_brand = arrSubBrand
                                // Set data sub brand if exist
                                arr = {
                                    nameKey: key,
                                    value: {
                                        name: self.selectedRowTable[key] ? self.selectedRowTable[key] : '',
                                        code: self.selectedRowTable[key] ? self.selectedRowTable[key] : ''
                                    }
                                }  
                            } else if (key == 'LOẠI CONTENT' || key == 'LOẠI_CONTENT' || key == 'LOẠI-CONTENT') {
                                this.listTypeContent = dataForm[key]
                                let type = JSON.parse(JSON.stringify(this.listTypeContent))
                                let data = type[self.selectedRowTable["BRAND*"]] // Check and push to brand
                                let arrType = []
                                // this.typeContent = null
                                for (let i = 0; i< data.length; i++) {
                                    let object = {
                                        name: data[i],
                                        code: data[i]
                                    }
                                    arrType.push(object)
                                }
                                this.typeContent = arrType
                                // Set data sub brand if exist
                                arr = {
                                    nameKey: key,
                                    data: arrType,
                                    value: {
                                        name: self.selectedRowTable[key] ? self.selectedRowTable[key] : 'Không có dữ liệu',
                                        code: self.selectedRowTable[key] ? self.selectedRowTable[key] : 'Không có dữ liệu'
                                    }
                                }
                            } else {
                            if (Array.isArray(dataForm[key])) {
                                let arrF = []
                                if (dataForm[key].length > 0) {
                                    for (let i = 0; i< dataForm[key].length; i++) {
                                        let object = {
                                            name: dataForm[key][i],
                                            code: dataForm[key][i]
                                        }
                                        arrF.push(object)
                                    }
                                    arr = {
                                        data: arrF,
                                        required: 'false',
                                        nameKey: key,
                                        type:"ARRAY",
                                        value: {
                                            name: self.selectedRowTable[key] ? self.selectedRowTable[key] : '',
                                            code: self.selectedRowTable[key] ? self.selectedRowTable[key] : ''
                                        }
                                    }
                                } else {
                                    let object = {
                                        name: "Không có dữ liệu",
                                        code: "Không có dữ liệu"
                                    }
                                    arrF.push(object)
                                    arr = {
                                        data: arrF,
                                        required: 'false',
                                        nameKey: key,
                                        type:"ARRAY",
                                        value: {
                                            name: self.selectedRowTable[key] ? self.selectedRowTable[key] : '',
                                            code: self.selectedRowTable[key] ? self.selectedRowTable[key] : ''
                                        }
                                    }
                                }
                            }  else if (typeof dataForm[key] === 'object' && dataForm[key] !== 'null')  {
                                if (key.toUpperCase().includes('KÊNH*') || key.toUpperCase().includes('KÊNH') || key.toUpperCase().includes('KENH')) {
                                    this.listChanel = dataForm[key]
                                } else if (key.toUpperCase().includes('PIC*') || key.toUpperCase().includes('PIC')) {
                                    this.listPIC = dataForm[key]
                                }
                            } else {
                                arr = {
                                    data: dataForm[key] ? dataForm[key] : 'STRING',
                                    required: 'false',
                                    nameKey: key,
                                    value:self.selectedRowTable[key] ? self.selectedRowTable[key] : ''
                                }  
                            }
                            }
                            this.listDataForm.push(arr)
                        } else {
                            if (Array.isArray(dataForm[key])) {
                                let arrF = []
                                if (dataForm[key].length > 0) {
                                    for (let i = 0; i< dataForm[key].length; i++) {
                                        let object = {
                                            name: dataForm[key][i],
                                            code: dataForm[key][i]
                                        }
                                        arrF.push(object)
                                    }
                                    arr = {
                                        data: arrF,
                                        required: 'true',
                                        nameKey: key,
                                        type:"ARRAY",
                                        value:''
                                    }
                                } else {
                                    let object = {
                                        name: "Không có dữ liệu",
                                        code: "Không có dữ liệu"
                                    }
                                    arrF.push(object)
                                    arr = {
                                        data: arrF,
                                        required: 'true',
                                        nameKey: key,
                                        type:"ARRAY",
                                        value:''
                                    }
                                }
                            }
                            // push array data cheked
                            this.listCheckCampaign = arr
                                // set checked if isset
                            if (Array.isArray(self.selectedRowTable[key])) {
                                if (self.selectedRowTable[key].length > 0) {
                                    let ar = []
                                    this.checkedCampain = []
                                    let ob = {}
                                    for (const select in self.selectedRowTable[key]) {
                                        ob = {
                                            name: self.selectedRowTable[key][select],
                                            code: self.selectedRowTable[key][select]
                                        }
                                        ar.push(ob)
                                    }
                                    this.checkedCampain = ar
                                }
                            } else {
                                if (key in self.selectedRowTable) {
                                    let ar = []
                                    this.checkedCampain = []
                                    let ob = {}
                                    let arrSplit = self.selectedRowTable[key].split(',')
                                    for (const select in arrSplit) {
                                        ob = {
                                            name: arrSplit[select],
                                            code: arrSplit[select]
                                        }
                                        ar.push(ob)
                                    }
                                    this.checkedCampain = ar
                                }
                            } //ENd if else
                            
                            }
                        }
                    } // End if lôp  dataform
                }).catch(err => {
                    this.$toast.add({
                        severity:'error', 
                        summary: 'Error Message', 
                        detail:'Err: ' + err, life: 3000
                    })
                }).finally(() => {
                    this.blockedUser = false
                    this.$toast.add({
                        severity:'success', 
                        summary: 'Informations', 
                        detail:'Data has loaded!', life: 3000
                    })
                })
            }
        },
        insertRecord(type){
            if (type == 'insert') {
                let dataInsertForm = []
                let ob = {}
                if (this.listDataForm) {
                    let check = this.checkObjectNotNull(this.listDataForm);
                    if (check == false) {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Error Message', 
                            detail:'Vui lòng nhập đầy dữ liệu vào form có dấu *:  ', life: 3000
                        })
                    } else {
                        let err = false
                        for (let index in this.listDataForm){
                            if(typeof this.listDataForm[index].value === 'object' && this.listDataForm[index].value !== null){
                                if (this.listDataForm[index].data == 'DATE') {
                                ob[this.listDataForm[index].nameKey] =this.formatDate(this.listDataForm[index].value, 'DATE')
                                } else if(this.listDataForm[index].data == 'HOUR'){
                                let time = this.formatDate(this.listDataForm[index].value, 'HOUR').slice(0,-3)
                                ob[this.listDataForm[index].nameKey] =time
                                } else {
                                ob[this.listDataForm[index].nameKey] =this.listDataForm[index].value.code
                                }
                            } else if (this.listDataForm[index].data == 'NUMBER') {
                                if (this.listDataForm[index].value != ''){
                                if (this.listDataForm[index].value < 0) {
                                    err = true
                                    this.$toast.add({
                                        severity:'error', 
                                        summary: 'Error Message', 
                                        detail:'Vui lòng nhập dữ liệu lớn hơn 0:  ', life: 3000
                                    })
                                } else {
                                    ob[this.listDataForm[index].nameKey] =this.listDataForm[index].value
                                }
                                } else {
                                ob[this.listDataForm[index].nameKey] = this.listDataForm[index].value
                                }
                            }
                            else {
                                ob[this.listDataForm[index].nameKey] =this.listDataForm[index].value
                            }
                        }// ENd for
                        // Push checked campaign to dataa
                        let arrCheck = []
                        if (typeof this.listCheckCampaign === 'object' && this.listCheckCampaign !== 'null') {
                            if(this.checkedCampain.length > 0){
                                for (let c = 0;  c < this.checkedCampain.length; c++) {
                                    let value = this.checkedCampain[c].name
                                    arrCheck.push(value)
                                }
                                ob[this.listCheckCampaign.nameKey] = arrCheck
                            }
                            ob['SHORT_NAME'] = localStorage.getItem('SHORT_NAME')
                        }
                        // convert data
                        this.afterConvertData = this.convertDataPlan()
                        if (this.afterConvertData.length > 0){
                            ob['Kế hoạch triển khai'] = this.afterConvertData
                        }
                        if (err == false) {
                            dataInsertForm.push(ob)
                            this.saveData(dataInsertForm)
                        }
                    } // ENd check isset required
                // console.log("data after check: " + JSON.stringify(dataInsertForm))
                }
            } else if (type == 'update'){
                let dataInsertForm = []
                let ob = {}
                if (this.listDataForm) {
                let check = this.checkObjectNotNull(this.listDataForm);
                if (check == false) {
                    this.$toast.add({
                    severity:'error', 
                    summary: 'Error Message', 
                    detail:'Vui lòng nhập đầy dữ liệu vào form có dấu *:  ', life: 3000
                    })
                } else {
                    let err = false
                    for (let index in this.listDataForm){
                    if(typeof this.listDataForm[index].value === 'object' && this.listDataForm[index].value !== null){
                        if (this.listDataForm[index].data == 'DATE') {
                        ob[this.listDataForm[index].nameKey] =this.formatDate(this.listDataForm[index].value, 'DATE')
                        } else if(this.listDataForm[index].data == 'HOUR'){
                        let time = this.formatDate(this.listDataForm[index].value, 'HOUR').slice(0,-3)
                        ob[this.listDataForm[index].nameKey] = time
                        // ob[this.listDataForm[index].nameKey] =this.formatDate(this.listDataForm[index].value, 'HOUR')
                        } else {
                            ob[this.listDataForm[index].nameKey] =this.listDataForm[index].value.code
                        }
                    } else if (this.listDataForm[index].data == 'NUMBER') {
                        if (this.listDataForm[index].value != '') {
                            if (this.listDataForm[index].value < 0){
                                err = true
                                this.$toast.add({
                                severity:'error', 
                                summary: 'Error Message', 
                                detail:'Vui lòng nhập dữ liệu lớn hơn 0:  ', life: 3000
                                })
                            } else {
                                    ob[this.listDataForm[index].nameKey] = this.listDataForm[index].value
                                }
                            } else {
                                ob[this.listDataForm[index].nameKey] = this.listDataForm[index].value
                            }
                        } else {
                            ob[this.listDataForm[index].nameKey] =this.listDataForm[index].value
                        }
                    }// ENd for
                    // Push checked campaign to dataa
                    let arrCheck = []
                    if (typeof this.listCheckCampaign === 'object' && this.listCheckCampaign !== 'null') {
                        if(this.checkedCampain.length > 0){
                            for (let c = 0;  c < this.checkedCampain.length; c++) {
                                let value = this.checkedCampain[c].name
                                arrCheck.push(value)
                            }
                            ob[this.listCheckCampaign.nameKey] = arrCheck
                        }
                        ob['SHORT_NAME'] = localStorage.getItem('SHORT_NAME')
                    }
                    //Convert data
                    this.afterConvertData = this.convertDataPlan()
                    if (this.afterConvertData.length > 0){
                        ob['Kế hoạch triển khai'] = this.afterConvertData
                    }
                    
                    if (err == false) {
                        dataInsertForm.push(ob)
                        this.updateData(dataInsertForm)
                    }
                } // ENd check isset required
                // console.log("data after check: " + JSON.stringify(dataInsertForm))
                }
            }
        },
        saveData(dataInsertForm){
            // Insert record
            let self = this
            self.blockedUser = true
            let bodyFormData = new FormData();
                bodyFormData.append('loginToken', localStorage.getItem('jwt'));
                bodyFormData.append('mode', "insert");
                bodyFormData.append('collectionName', self.formName);
                bodyFormData.append('records', JSON.stringify(dataInsertForm));
            self.axios({
                method: "post",
                url: self.$urlAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(response => {
                dataInsertForm[0]._id = response.data._id
                if(typeof this.listCheckCampaign === 'object' && this.listCheckCampaign !== 'null'){
                    dataInsertForm[0]['NGÀY TẠO'] = response.data['NGÀY TẠO']
                    dataInsertForm[0]['NGƯỜI TẠO'] = localStorage.getItem('SHORT_NAME')
                    delete dataInsertForm[0]['SHORT_NAME']
                }
                self.blockedUser = false
                if (response.data.status == "success"){
                    self.$toast.add({
                        severity:'success', 
                        summary: 'Success Message', 
                        detail:'Insert success', 
                        life: 3000
                    });
                }
            }).catch(err => 
                self.$toast.add({
                    severity:'error', 
                    summary: 'Error Message', 
                    detail:'Err' + err, life: 3000
                })
            )
            .finally(() => {
                let dataToHome = {
                    data: dataInsertForm[0],
                    formNameData: self.formName
                }
                self.$emit('insertDataModal', dataToHome)
                // self.products = [dataInsertForm[0], ...self.products]
                self.listDataForm = []
                self.closeModal()
            })
        },
        updateData(dataInsertForm){
            let strKey = 'trạng thái*'
            let typeState = null;
            this.blockedUser = true
            // Check state 
            for (const key in dataInsertForm[0]) {
                if (key.toLowerCase().indexOf(strKey) !== -1) {
                    typeState = dataInsertForm[0][key]
                }   
            }
            // Check task
            if (typeState.toLowerCase() == 'đã duyệt' && this.formName == 'PLAN') {
                let checkApproved = this.checkApproved();
                let checkStateApproved = this.checkStateApproved();
                if (checkApproved == false && checkStateApproved == false) {
                    this.update(dataInsertForm)
                } else {
                    this.blockedUser = false
                    this.$toast.add({
                        severity:'error', 
                        summary: 'Error Message', 
                        detail:'Tất cả kế hoạch phải được duyệt', life: 3000
                    })
                }
            } else if (typeState.toLowerCase().includes('duyệt')) {
                let checkStateApproved = this.checkStateApproved();
                if (checkStateApproved == false) {
                    this.update(dataInsertForm)
                } else {
                    this.blockedUser = false
                    this.$toast.add({
                        severity:'error', 
                        summary: 'Error Message', 
                        detail:'Tất cả Form input Link phải được điền ', life: 3000
                    })
                }
            }
            else {
                this.update(dataInsertForm)
            }
            // console.log(JSON.stringify(dataInsertForm[0]))
        },
        update(dataInsertForm){
            dataInsertForm[0].id=this.selectedRowTable._id
            //Compare two object, dataInsertForm & selectedRow. and merge data
            let result = {} 
            let keysData = Object.keys(dataInsertForm[0]);
            for (var key in this.selectedRowTable) {
                if (!keysData.includes(key)) {
                    result[key] = this.selectedRowTable[key];
                }
            }
            let data = [{...dataInsertForm[0], ...result }]
            //ENd
            let bodyFormData = new FormData();
                bodyFormData.append('loginToken', localStorage.getItem('jwt'));
                bodyFormData.append('mode', "update");
                bodyFormData.append('collectionName', this.formName);
                bodyFormData.append('records', JSON.stringify(data));
            let self = this
            self.axios({
                method: "post",
                url: this.$urlAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(response => {
                this.blockedUser = false
                if (response.data.status == "success"){
                    let ob = {}
                    for (const key in this.listDataForm) {
                        if(typeof this.listDataForm[key].value === 'object' && this.listDataForm[key].value !== null){
                        if (this.listDataForm[key].data == 'DATE') {
                            ob[this.listDataForm[key]['nameKey']] =this.formatDate(this.listDataForm[key].value, 'DATE')
                        } else if (this.listDataForm[key].data == 'HOUR'){
                            let time = this.formatDate(this.listDataForm[key].value, 'HOUR').slice(0,-3)
                            ob[this.listDataForm[key]['nameKey']] = time
                        } else {  
                            ob[this.listDataForm[key]['nameKey']] = this.listDataForm[key].value.name
                        }
                        } else {
                            ob[this.listDataForm[key]['nameKey']] = this.listDataForm[key].value
                        }
                    }
                    ob['_id'] = response.data._id
                    if (this.afterConvertData.length > 0){
                        ob['Kế hoạch triển khai'] = this.afterConvertData
                    }
                    if (typeof this.listCheckCampaign === 'object' && this.listCheckCampaign !== 'null') {
                        ob['NGÀY TẠO'] = response.data['NGÀY TẠO']
                        ob['NGƯỜI TẠO'] = localStorage.getItem('SHORT_NAME')
                        delete dataInsertForm[0]['SHORT_NAME']
                        if(this.checkedCampain.length > 0){
                            let str = ''
                            for (let i = 0; i< this.checkedCampain.length; i++) {
                                str += this.checkedCampain[i].name + ','
                            }
                            ob['TASK CỦA CHIẾN DỊCH*'] = str.slice(0, -1)
                        } else {
                            ob['TASK CỦA CHIẾN DỊCH*'] = []
                        }

                    }
                    // back data to parent component
                    self.$emit('updateData', ob) // update new data to datatable
                    // console.log(ob)
                    self.$toast.add({
                        severity:'success', 
                        summary: 'Success Message', 
                        detail:'Updated success', 
                        life: 3000
                    });
                } else {
                    self.$toast.add({
                        severity:'error', 
                        summary: 'Error Message', 
                        detail:'Err: ' + JSON.stringify(response.data.reason), life: 3000
                    })
                }
            }).catch(err => 
                self.$toast.add({
                    severity:'error', 
                    summary: 'Error Message', 
                    detail:'Err' + err, life: 3000
                })
            )
            .finally(() => {
                // self.blockedUser = false
                self.closeModal()
            })
        },
        checkStateApproved(){
            let self = this
            if (self.listDataForm.length > 0){
                let checkHasdata = false
                for(let i = 0; i < self.listDataForm.length; i++){
                    for (const key in self.listDataForm[i]) {
                        if (key == 'nameKey' && self.listDataForm[i][key].toUpperCase().includes('LINK')) {
                            if ( self.listDataForm[i]['value'] == '' || self.listDataForm[i]['value'] == null) {
                                checkHasdata = true
                                break;
                            }
                        }
                    }
                }
                console.log('key: ' + checkHasdata)
                return checkHasdata;
            }
        },
        checkApproved(){
            if (this.tableTest.length > 0){
                this.errorApproved = false
                for (let index = 0; index < this.tableTest.length; index++) {
                    const element = this.tableTest[index];
                    if (element.data.length > 0) {
                        for (let i = 0; i < element.data.length; i++) {
                            const data = element.data[i];
                            if (data['key'] == 'DUYỆT') {
                                if ( data['value'].name.toLowerCase() != 'duyệt') {
                                    this.errorApproved = true
                                    break;
                                }
                            }
                        }
                    }
                }
                return this.errorApproved;
            }
        },
        convertDataPlan(){
            if (this.tableTest.length > 0) {
                let arrData = []
                for (let i = 0; i < this.tableTest.length; i++){
                    let data = this.tableTest[i].data
                    if (data.length > 0) {
                        let ob = {}
                        for (let j = 0; j < data.length; j++){
                            ob['planId'] = data[j]['planId']
                            if (typeof data[j].value === 'object' && data[j].value !== null){
                                ob[data[j].key] = data[j].value.name
                            } else {
                                ob[data[j].key] = data[j].value
                            }
                        }
                        arrData.push(ob)
                    }
                }
                return arrData;
            } else {
                let arrData = []
                return arrData;
            }
        },
        abstractTable(dataPlan, itemtable){
            let data = dataPlan
            let ob = {
                key: '',
                type:'',
                value:'',
                required: '',
            }
            for (let key in data){
                //push data to types
                let regexType = key.match('PHÂN');
                if (regexType) {
                    this.types = {}
                    let dataType = regexType.input
                    this.types = data[dataType]
                }
                if (key.includes('*')) {
                    if(Array.isArray(data[key])){
                        if (data[key].length > 0){
                            let arrF = []
                            for (let i = 0; i < data[key].length; i++) {
                                let object = {
                                    name: data[key][i],
                                    code: data[key][i]
                                }
                                arrF.push(object)
                            } //Convert value to arr ob
                            ob = {
                                key: key,
                                type: "ARRAY",
                                data: arrF,
                                value: null,
                                required: true
                            }
                        } else {
                            let object = {
                                name: "Không có dữ liệu",
                                code: "Không có dữ liệu"
                            }
                            let arrF = [object]
                            ob = {
                                key: key,
                                type: "ARRAY",
                                data: arrF,
                                value: null,
                                required: true
                            }
                        }
                    } else { //End check is array
                        ob = {
                            key: key,
                            type: data[key],
                            value: '',
                            required: true
                        }
                    }
                    this.abstractDataTable.push(ob)
                } else { //else check required
                    if(Array.isArray(data[key])){
                        if (data[key].length > 0){
                            let arrF = []
                            for (let i = 0; i < data[key].length; i++) {
                                let object = {
                                    name: data[key][i],
                                    code: data[key][i]
                                }
                                arrF.push(object)
                            } //Convert value to arr ob
                            ob = {
                                key: key,
                                type: "ARRAY",
                                data: arrF,
                                value: null,
                                required: false
                            }
                        } else {
                            let object = {
                                name: "Không có dữ liệu",
                                code: "Không có dữ liệu"
                            }
                            let arrF = [object]
                            ob = {
                                key: key,
                                type: "ARRAY",
                                data:arrF,
                                value: null,
                                required: true
                            }
                        }
                    } else if (typeof data[key] ==='object' && data[key] !=='null') {
                        ob = {
                            data: [],
                            key: "PHÂN LOẠI",
                            value: [{
                                name: 'Không có dữ liệu',
                                code: 'Không có dữ liệu'
                            }],
                            type: "OBJECT",
                            required: true
                        }
                    } else {
                        ob = {
                            data: '',
                            key: key,
                            type: data[key],
                            value: '',
                            required: false
                        }
                    }
                    this.abstractDataTable.push(ob)
                }
            } // End for
            // Push data if exist 
            if (itemtable != null && itemtable.length > 0) {
                let self = this
                let abstract = self.abstractDataTable
                for (let index = 0; index < itemtable.length; index++) {
                    const element = itemtable[index];
                    let arr = []
                    let keyBrand = ''
                    Object.keys(element).forEach(ele => {
                        let ob = {}
                        for (let key in abstract) {
                            if (abstract[key].key == ele && abstract[key].key == 'CÔNG VIỆC*') {
                                keyBrand = element[ele]
                            }
                            if (abstract[key].key == ele) {
                                if (abstract[key].type == 'ARRAY') {
                                    ob = {
                                        data:abstract[key].data,
                                        key: abstract[key].key,
                                        value: {
                                            name: element[ele],
                                            code: element[ele]
                                        },
                                        type: abstract[key].type,
                                        required: abstract[key].required,
                                        planId: itemtable[index]['planId']
                                    }
                                    // console.log("abstract[key]: " +abstract[key].key)
                                    arr.push(ob)
                                } else if (abstract[key].type == 'OBJECT'){
                                    // Search 
                                    self.getUpdateDataPlan(keyBrand, index)
                                    ob = {
                                        data:abstract[key].data,
                                        key: abstract[key].key,
                                        value: {
                                            name: element[ele],
                                            code: element[ele]
                                        },
                                        type: abstract[key].type,
                                        required: abstract[key].required,
                                        planId: itemtable[index]['planId']
                                    }
                                    arr.push(ob)
                                } else {
                                    ob = {
                                        data:abstract[key].data,
                                        key: abstract[key].key,
                                        value: element[ele],
                                        type: abstract[key].type,
                                        required: abstract[key].required,
                                        planId: itemtable[index]['planId']
                                    }
                                    arr.push(ob)
                                }
                            }
                        }
                    })
                    if (this.actionTypesProp == 'update') {
                        this.tableTest['hiddenClass'] = false
                    }
                    let pushBtnInsertTask = {
                        data: arr,
                        hiddenClass: false
                    }
                    self.tableTest.push(pushBtnInsertTask)
                }
            }
        },
        // Onchange date. 
        //if date begin none, date End none,
        // if date begin exist data, date End >= date begin
        onChangeDate(selectedKey){
            // let today = new Date();
            for (let index = 0; index < this.listDataForm.length; index++) {
                const element = this.listDataForm[index];
                let key = 'NGÀY KẾT THÚC'
                if (element.nameKey == key) {
                    element.value ='' 
                }
                if (selectedKey == element.nameKey) {
                    if (element.value != '') {
                        this.minDateBegin = element.value
                    }
                }
            }
        },
        // Support function
        getUniqueListBy(arr, key) {
            return [...new Map(arr.map(item => [item[key], item])).values()]
        },
        convertArrayToObjectSelected(eventValue, list){
            let arrSubBrand = []
            for (const key in list) {
                if (key == eventValue) {
                    for (let i = 0; i< list[key].length; i++) {
                        let object = {
                            name: list[key][i],
                            code: list[key][i]
                        }
                        arrSubBrand.push(object)
                    }
                }
            }
            let data = this.getUniqueListBy(arrSubBrand, 'name')
            return data;
        },
        onChangeBrand(event){
            if (event) {
                this.sub_brand = this.convertArrayToObjectSelected(event.value.name, this.listSub_brand)
                this.sub_listChanel = this.convertArrayToObjectSelected(event.value.name, this.listChanel)
                this.sub_listPic = this.convertArrayToObjectSelected(event.value.name, this.listPIC)
                // Set all value change with brand is null
                let value_sub = ['SUB_BRAND','SUB-BRAND', 'KÊNH', 'PIC'];
                for (let index = 0; index < value_sub.length; index++) {
                    const element = value_sub[index];
                    Object.keys(this.listDataForm).forEach(x => {
                        let keyData = this.listDataForm[x].nameKey
                        let match = keyData.match(element)
                        if (match) {
                            let str = match.input
                            if (str == this.listDataForm[x].nameKey && typeof this.listDataForm[x].value === 'object' && this.listDataForm[x].value !=='undefined') {
                                this.listDataForm[x].value = {}
                            }
                        }
                    })
                }
                // Push data to type content
                if (typeof this.listTypeContent == 'object' && this.listTypeContent !== null){
                    this.typeContent = null
                    let arrTypeContent = []
                    let nameBrand = event.value.name
                    let dataTypeContent = JSON.parse(JSON.stringify(this.listTypeContent[nameBrand]));
                    if (dataTypeContent.length > 0) {
                        for (let j = 0; j < dataTypeContent.length; j++) {
                            let object = {
                                name: dataTypeContent[j],
                                code: dataTypeContent[j]
                            }
                            arrTypeContent.push(object)
                        }
                        this.typeContent = arrTypeContent
                    } else {
                        this.typeContent = {
                            name: 'Không có dữ liệu',
                            code: 'Không có dữ liệu'
                        }
                    }
                }
            }
            

        }, //end check onChange brand
        checkObjectNotNull(obj){
            for (var key in obj) {
                if (obj[key].required == 'true' && obj[key].value == "")
                    return false;
            }
            return true;
        }, //end check checkObjectNotNull
        // Close current modal equal push to parent
        closeModal(){
            this.openDialog = false
            this.$emit('openDialog', this.openDialog)
        },
        onChangeJob(event, indexRow){
            if (event){
                this.listTypes = []
                let data = []
                data = this.types[event.value.name]
                if (data.length > 0) {
                    let ob = {}
                    for (let index = 0; index < data.length; index++) {
                        if (data[index] != ''){
                            ob = {
                                name: data[index],
                                code: data[index]
                            }
                            if(!this.listTypes.some(name => name.name === data[index])){
                                this.listTypes.push(ob)
                            }
                        } else {
                            ob = {
                                name: 'Không có dữ liệu',
                                code: 'Không có dữ liệu'
                            }
                            this.listTypes.push(ob)
                        }
                    }
                } else {
                    let ob = {
                        name: 'Không có dữ liệu',
                        code: 'Không có dữ liệu'
                    }
                    this.listTypes.push(ob)
                }
                // Push Object data
                this.listTypesPlan[indexRow] = this.listTypes
            } //End check exist event 
        },
        getUpdateDataPlan(nameBrand, indexRow){
            if (nameBrand){
                this.listTypes = []
                let data = []
                data = this.types[nameBrand]
                if (data.length > 0) {
                    let ob = {}
                    for (let index = 0; index < data.length; index++) {
                        if (data[index] != ''){
                            ob = {
                                name: data[index],
                                code: data[index]
                            }
                            if(!this.listTypes.some(name => name.name === data[index])){
                                this.listTypes.push(ob)
                            }
                        } else {
                            ob = {
                                name: 'Không có dữ liệu',
                                code: 'Không có dữ liệu'
                            }
                            this.listTypes.push(ob)
                        }
                    }
                } else {
                    let ob = {
                        name: 'Không có dữ liệu',
                        code: 'Không có dữ liệu'
                    }
                    this.listTypes.push(ob)
                }
                // Push Object data
                this.listTypesPlan[indexRow] = this.listTypes
            } //End check exist event 
        },
        addRowTable(){
            let data = this.abstractDataTable
            let ob = {}
            let d = []
            for (let i in data) {
                // console.log('data[i]: ' + JSON.stringify(data[i]))
                ob = {
                    data:data[i].data,
                    key: data[i].key,
                    value: data[i].value = '',
                    type: data[i].type,
                    required: data[i].required,
                }
                d.push(ob)
            }
            //Set list default 
            this.listTypesPlan.push([{
                name: 'Không có dữ liệu',
                code: 'Không có dữ liệu'
            }])
            d.hiddenClass = false
            //add row table
            let pushBtnInsertTask = {
                data: d,
                hiddenClass: true
            }
            this.tableTest.push(pushBtnInsertTask)
        },
        toggle(id){
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        },
        formInsertTask(indexRow){
            this.openInsertTask = true
            let data = this.tableTest[indexRow].data
            let word = "CÔNG";
            let newRegex = new RegExp(word)
            Object.keys(data).forEach(d => {
                let string = data[d].key
                let matchRegex = newRegex.test(string)
                if (matchRegex){
                    this.selectedJob = data[d].value.name
                }
            })
        },
        //recei datachild component Insert task 
        openInsertTaskModal(isOpen){
            this.openInsertTask = isOpen
        },
        removeItem(indexRow){
            this.tableTest.splice(indexRow, 1)
        },
        formatDate(dateString, type){
            if (type == 'HOUR'){
                let date = new Date(dateString);
                // let dateLocal = date.toLocaleTimeString().split()
                return date.toLocaleTimeString();
            } else if (type == 'DATE') {
                const date = new Date(dateString);
                return date.toLocaleDateString('en-GB');
            }
        },
        insertRowTable(dataInsertTable){
            this.$emit('insertDataModal', dataInsertTable)
        }
    }
}
</script>
<style scoped>
.tableplan{
    /* overflow: auto; */
}
table{
    width: 100%;
    border-collapse: collapse;
    width: max-content;
}
table thead{
    text-align: left;
}
table .action-table{
    width: 5%;
    text-align: center;
    cursor: pointer;
}
table tbody tr{
    color: #212529;
    transition: box-shadow 0.15s;
    outline-color: rgba(38, 143, 255, 0.5);
}
table thead th,
table tbody tr td{
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 1px;
    padding: 0.5rem 0.5rem;
}
.btn-add{
    margin-top: 10px;
    margin-right: 10px;
    float: left;
}
.hidden{
    display: none;
}
.activeClass{
    display: block;
}
.box-expand{
    width: 100%;
}
.box-expand th{
    width: 10%;
}
.box-expand td{
    width: 90%;
}
.tableplan .dropdown-insert{
    width: 100%;
}
.hiddenButton{
    display: none;
}
</style>