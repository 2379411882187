<template>
    <div>
        <ProgressSpinner v-show="isLoadingData"/>
        <div class="box-table" v-show="isLoadingData == false">
            <!-- Dropdown list table -->
            <div class="list-table">
                <Dropdown v-model="selectedListTable" :options="listTable" optionLabel="name" placeholder="Select a Table" />
            </div>
            <!-- Show table by selected list table -->
            <div class="box-average">
                <!-- <ToggleButton v-model="hiddenAverage" onLabel="Ẩn" offLabel="Hiển thị" onIcon="pi pi-chevron-circle-down" offIcon="pi pi-chevron-circle-right" /> -->
                <div class="box-table-ava">
                    <h4>{{titleAverage}}</h4>
                    <DataTable :value="itemsAverage" showGridlines class="table-report p-datatable-gridlines p-datatable-striped" >
                        <template #empty>
                            Data not found
                        </template>
                        <template #loading>
                            Loading data. Please wait.
                        </template>
                        <Column v-for="col of columnsAverage" :field="col.field" :header="col.header" :key="col.field">
                            <template #body="{data}" v-if="selectedListTable.name == 'TỔNG SỐ CÔNG VIỆC' && (col.field.toUpperCase().includes('YOUTUBE') || col.field.toUpperCase().includes('FANPAGE') || col.field.toUpperCase().includes('DESIGN') || col.field.toUpperCase().includes('DEV') || col.field.toUpperCase().includes('CONTENT')  || col.field.toUpperCase().includes('PR') || col.field.toUpperCase().includes('GROUP') || col.field.toUpperCase().includes('SOCIAL') || col.field.toUpperCase().includes('SEO-INLINK') || col.field.toUpperCase().includes('SEO-OUTLINE') || col.field.toUpperCase().includes('SEM'))">
                                <span v-if="data[col.field]">
                                    {{data[col.field]['Count'] ? data[col.field]['Count'] : data[col.field]}}
                                    <br/>
                                    {{data[col.field]['Percent'] ? data[col.field]['Percent'] : data[col.field]}} %
                                </span>
                            </template>
                            <template #body="{data}" v-else-if="selectedListTable.name == 'TỔNG SỐ CÔNG VIỆC' && col.field.toUpperCase().includes('MEMBERS')">
                                {{ Array.isArray(data[col.field]) ? data[col.field].join() : data[col.field]}}
                            </template>
                            <template #body="{data}" v-else-if="selectedListTable.name == 'TỔNG SỐ ĐIỂM KPI' && (col.field.toUpperCase().includes('PICNAME') || col.field.toUpperCase().includes('BRAND'))">
                                {{ data[col.field]}}
                            </template>
                            <template #body="{data}" v-else-if="selectedListTable.name == 'TỔNG SỐ ĐIỂM KPI' && (!col.field.toUpperCase().includes('PICNAME') || !col.field.toUpperCase().includes('BRAND'))">
                                <!-- {{data[col.field] != '' ? data[col.field].KPI : data[col.field]}} -->
                                {{data[col.field] ?  data[col.field].KPI : '' }} 
                            </template>
                            <template #body="{data}" v-else>
                                {{data[col.field]}} {{col.field.includes('Rate') ? '%' : ''}} {{col.field.includes('Late') ? 'Ngày' : ''}} {{col.field.includes('Time') ? 'Ngày' : ''}}
                            </template>
                            <!-- <template #body="{data}" v-if="selectedListTable.name == 'TỔNG SỐ CÔNG VIỆC'">
                                {{data[col.field].Count}}<br/>
                                {{data[col.field].Percent}} %
                            </template>
                            <template #body="{data}" v-else-if="selectedListTable.name == 'TỔNG SỐ ĐIỂM KPI'">
                                {{data[col.field].KPI}}
                            </template>
                            <template #body="{data}" v-else>
                                {{data[col.field]}} {{col.field.includes('Rate') ? '%' : ''}} {{col.field.includes('Late') ? 'Ngày' : ''}} {{col.field.includes('Time') ? 'Ngày' : ''}}
                            </template> -->
                        </Column>
                    </DataTable>
                </div>
                <!-- <label for="">Hiển thị điểm trung bình</label><ToggleButton v-model="hiddenAvarage" onIcon="pi pi-chevron-circle-right" offIcon="pi pi-chevron-circle-down" /> -->
            </div>

            <h3>Bảng dữ liệu chi tiết</h3>
            <DataTable :value="products" showGridlines class="table-report table-report-detail p-datatable-gridlines p-datatable-striped" :paginator="true" :rows="20"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[20,50,100]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :filters="filters" >
            <template #header>
                <div class="p-d-flex p-jc-between">
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilter1()"/>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global']" placeholder="Keyword Search" />
                    </span>
                </div>
            </template>
            <template #empty>
                Data not found
            </template>
            <template #loading>
                Loading data. Please wait.
            </template>
                <ColumnGroup type="header">
                    <Row>
                        <Column v-for="col of columns" :header="col.header" :key="col.field"  :sortable="true" :field="col.field">
                        </Column>
                    </Row>
                </ColumnGroup>
                <Column v-for="col of columns" :field="col.field" :key="col.field">
                    <template #body="{data}" v-if="selectedListTable.name == 'TỔNG SỐ CÔNG VIỆC' && (col.field.toUpperCase().includes('YOUTUBE') || col.field.toUpperCase().includes('FANPAGE') || col.field.toUpperCase().includes('DESIGN') || col.field.toUpperCase().includes('DEV') || col.field.toUpperCase().includes('CONTENT')  || col.field.toUpperCase().includes('PR') || col.field.toUpperCase().includes('GROUP') || col.field.toUpperCase().includes('SOCIAL') || col.field.toUpperCase().includes('SEO-INLINK') || col.field.toUpperCase().includes('SEO-OUTLINE') || col.field.toUpperCase().includes('SEO-OUTLINE') || col.field.toUpperCase().includes('SEM'))">
                        <span v-if="data[col.field]">
                            {{data[col.field]['Count'] ? data[col.field]['Count'] : data[col.field]}}
                            <br/>
                            {{data[col.field]['Percent'] ? data[col.field]['Percent'] : data[col.field]}} %
                        </span>
                    </template>
                    <template #body="{data}" v-else-if="selectedListTable.name == 'TỔNG SỐ CÔNG VIỆC' && col.field.toUpperCase().includes('MEMBERS')">
                        {{ Array.isArray(data[col.field]) ? data[col.field].join() : data[col.field]}}
                    </template>
                    <template #body="{data}" v-else-if="selectedListTable.name == 'TỔNG SỐ ĐIỂM KPI' && (col.field.toUpperCase().includes('PICNAME') || col.field.toUpperCase().includes('BRAND'))">
                        {{ data[col.field]}}
                    </template>
                    <template #body="{data}" v-else-if="selectedListTable.name == 'TỔNG SỐ ĐIỂM KPI' && (!col.field.toUpperCase().includes('PICNAME') || !col.field.toUpperCase().includes('BRAND'))">
                        {{data[col.field] ?  data[col.field].KPI : '' }} 
                    </template>
                    <template #body="{data}" v-else>
                        {{data[col.field]}} {{col.field.includes('Rate') ? '%' : ''}} {{col.field.includes('Late') ? 'Ngày' : ''}} {{col.field.includes('Time') ? 'Ngày' : ''}}
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>
<script>
// import newKPIJSON from './../../assets/NEW-KPI.json'
export default {
    name: 'ReportTable',
    props: ['title','searchValue', 'selectedBrandHeader'],
    data () {
        return {
            products: [],
            columns: [],
            data:'',
            listTable:[],
            filters: {},
            listBrand:null,
            selectedListTable:'',
            isLoadingData: false,
            hiddenAverage: false,
            itemsAverage:[],
            columnsAverage: [],
            titleAverage: ''
        }
    },
    created(){
        this.getData()
    },
    watch: {
        searchValue(newValue, oldValue){
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.createTableAfterSelected(this.selectedListTable.name)
                this.createTableAverage(this.selectedListTable.name)
                this.filters['global'] = newValue.text
            }
        },
        selectedListTable(newValue, oldValue){
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.createTableAfterSelected(newValue.code)
                this.createTableAverage(newValue.code)
            }
        }
    },
    methods: {
        createTableAverage(nameTable){
            this.itemsAverage = []
            this.titleAverage = ''
            let objectAverage = JSON.parse(JSON.stringify(this.data[nameTable]));
            if (objectAverage.length > 0){
                this.itemsAverage = objectAverage.filter(val => {
                    return val["TopRow"] !== undefined
                })
            }

            if (this.itemsAverage[0] != ''){
                this.titleAverage = this.itemsAverage[0].TopRow
            }
            if (this.searchValue.brand != ''){
                this.itemsAverage = this.itemsAverage.filter(val => {
                    return val.Brand == this.searchValue.brand;
                })
                this.createColumnAverage(this.itemsAverage)
            } else {
                this.createColumnAverage(this.itemsAverage)
            }
        },
        createColumnAverage(objectAverage){
            this.columnsAverage = []
            let obCL = {}
            let arrAbstractCL = []
            for (let index = 0; index < objectAverage.length; index++) {
                Object.keys(objectAverage[index]).forEach(key => {
                    obCL = {}
                    if(key == 'PicName') {
                        obCL = {field: key, header: "Tên nhân viên - Team"}
                    } else if (key == 'MembersCount') {
                        obCL = {field: key, header: "Số nhân viên"}
                    } else if (key == 'TotalJobs') {
                        obCL = {field: key, header: "Tổng số công việc"}
                    } else if (key == 'CompleteRate') {
                        obCL = {field: key, header: "Tỷ lệ hoàn thành"}
                    } else if (key == 'DeadlineLate') {
                        obCL = {field: key, header: "Trễ Deadline"}
                    } else if (key == 'CompleteTime') {
                        obCL = {field: key, header: "Thời gian thực hiện"}
                    } else if (key == 'WorkQuality') {
                        obCL = {field: key, header: "Chất lượng công việc"}
                    } else if (key == 'KPI') {
                        obCL = {field: key, header: "Điểm KPI"}
                    } else if (key == 'Status') {
                            obCL = {field: key, header: "Trạng thái"}
                    } else if (key == 'TaskName') {
                        obCL = {field: key, header: "Công việc"}
                    } else if (key == 'JobPerMember') {
                        obCL = {field: key, header: "Công việc đầu người"}
                    }else {
                        obCL = {field: key, header: key}
                    }
                    // arrAbstractCL.push(key)
                    if (key != 'TopRow') {
                        if (arrAbstractCL.length > 0){
                            if (!arrAbstractCL.includes(key)){
                                arrAbstractCL.push(key)
                                this.columnsAverage.push(obCL)
                            }
                        } else {
                            arrAbstractCL.push(key)
                            this.columnsAverage.push(obCL)
                        }
                    }
                })
            }
        },
        makeListTable(){
            if(this.data !=''){
                this.listTable = []
                let ob = {}
                Object.keys(this.data).forEach(v => {
                    ob = {name: v, code: v}
                    this.listTable.push(ob)
                })

                if (this.listTable.length > 0){
                    this.selectedListTable = this.listTable[0]
                }
            }
        },
        createTableAfterSelected(nameTable){
            this.columns = []
            this.products = []
            if (nameTable != ''){
                let dupplicateObject = JSON.parse(JSON.stringify(this.data[nameTable]));
                if (dupplicateObject !== '') {
                    this.products = dupplicateObject.filter(val => {
                        return val["TopRow"] === undefined
                    })
                    if (this.searchValue.brand != ''){
                        if (this.products !== '') {
                            this.products = this.products.filter(val => {
                                return val.Brand == this.searchValue.brand;
                            })
                            this.makeColumnByTable()
                        }
                    } else {
                        this.makeColumnByTable()
                    }
                }
                
            }
        },
        makeColumnByTable(){
            let obCL = {}
            let self = this
            let arrAbstractCL = []
            console.log(JSON.stringify(self.products))
            // self.columns = []
            if (self.products.length > 0) {
                for (let index = 0; index < self.products.length; index++) {
                    Object.keys(self.products[index]).forEach(key => {
                        if(key == 'PicName') {
                            obCL = {field: key, header: "Tên nhân viên - Team", alpha: 'a'}
                        } else if (key == 'TeamName') {
                            obCL = {field: key, header: "Team", alpha: 'a'}
                        } else if (key == 'MembersCount') {
                            obCL = {field: key, header: "Số nhân viên", alpha: 'c'}
                        } else if (key == 'TotalJobs') {
                            obCL = {field: key, header: "Tổng số công việc", alpha: 'c'}
                        } else if (key == 'CompleteRate') {
                            obCL = {field: key, header: "Tỷ lệ hoàn thành", alpha: 'c'}
                        } else if (key == 'DeadlineLate') {
                            obCL = {field: key, header: "Trễ Deadline", alpha: 'c'}
                        } else if (key == 'CompleteTime') {
                            obCL = {field: key, header: "Thời gian thực hiện", alpha: 'c'}
                        } else if (key == 'WorkQuality') {
                            obCL = {field: key, header: "Chất lượng công việc", alpha: 'c'}
                        } else if (key == 'KPI') {
                            obCL = {field: key, header: "Điểm KPI", alpha: 'e'}
                        } else if (key == 'Status') {
                                obCL = {field: key, header: "Trạng thái", alpha: 'b'}
                        } else if (key == 'TaskName') {
                            obCL = {field: key, header: "Công việc", alpha: 'a'}
                        } else if (key == 'JobPerMember') {
                            obCL = {field: key, header: "Công việc đầu người", alpha: 'c'}
                        } else if (key == 'Members'){
                            return;
                        }  else {
                            obCL = {field: key, header: key, alpha: 'c'}
                        }
                        // obCL = {field: key, header: key}
                        if (key != 'TopRow') {
                            if (arrAbstractCL.length > 0){
                                if (!arrAbstractCL.includes(key)){
                                    arrAbstractCL.push(key)
                                    self.columns.push(obCL)
                                }
                            } else {
                                arrAbstractCL.push(key)
                                self.columns.push(obCL)
                            }
                        }
                            
                    })
                }
            }
            console.log('ss: ' + JSON.stringify(self.columns))
            self.columns = self.columns.sort(function(a, b) { 
                var nameA = a.alpha.toUpperCase(); // ignore upper and lowercase
                var nameB = b.alpha.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            })
            console.log('bb: ' + JSON.stringify(self.columns))
        },
        getData(){
            this.$toast.add({
                severity:'info', 
                summary: 'Information', 
                detail:'Data is loading, please wait for minute!', life: 3000
            })
            // Begin call api
            this.data= ''
            this.isLoadingData = true
            let bodyFormData = new FormData();
                bodyFormData.append('loginToken', localStorage.getItem('jwt'));
                bodyFormData.append('mode', "requestKPI");
                bodyFormData.append('startDate', this.searchValue.timeBegin);
                bodyFormData.append('endDate', this.searchValue.timeEnd);
            let self = this
            self.axios({
                method: "post",
                url: this.$urlAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(response => {
                if (response.data != '') {
                    this.data = response.data
                    this.makeListTable()
                    this.$toast.add({
                        severity:'success', 
                        summary: 'Information', 
                        detail:'Data has loaded, please select your table you want', life: 3000
                    })
                }
            }).catch(err => 
            {
                this.$toast.add({
                    severity:'error', 
                    summary: 'Error Message', 
                    detail:'Err' + err, life: 3000
                })
            }
            ).finally(() => this.isLoadingData = false)
        },
        
    }
}
</script>
<style>
.table-report .p-datatable-header{
    display: none !important;
}
.table-report-detail .p-datatable-thead > tr > th{
    min-width: 100px;
}
.box-table h3{
    text-align: left;
}
.p-datatable{
    border: 1px solid #E7E8F2;
}
.table-report .p-datatable-wrapper table{
    min-width: 100% !important;
}
.list-table{
    text-align: left;
    float: left;
    width: 100%;
    margin-bottom: 20px;
}
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td{
    padding: 0.5rem 0.5rem !important;
}
.box-average{
    display: flex;
    flex-flow: column;
    text-align: left;
    margin-bottom: 20px;
    width: 100%;
}
.box-average .p-button{
    background: #007bff !important;
    border-color: #007bff !important;
    color: #fff;
    width: fit-content;
}
</style>