<template>
    <div>
        <!-- <BlockUI :blocked="blockedPanel" :fullScreen="true"></BlockUI> -->
        <Dialog :header="formName" :visible.sync="openDialog" :maximizable="true" :showHeader="true" :modal="true" :closeOnEscape="false" :closable="false" :style="{width: '70vw'}" :key="formName">
            <div class="p-formgrid p-grid form-insert" >
                <div v-show="showDataNull">
                    Không có dữ liệu
                </div>
                <div class="p-field p-col-6 " v-for="val,index in listDataForm" :key="'insertForm-'+index">
                    <label for="basic" v-if="val.nameKey !=='SUB_BRAND' && val.nameKey !=='SUB BRAND' && val.nameKey !=='SUB-BRAND'">{{val.nameKey}}</label>
                    <Dropdown v-model="val.value" :filter="true" class="dropdown-insert" :options="val.data" v-if="val.type == 'ARRAY' && val.nameKey == 'BRAND*'" optionLabel="name" @change="onChangeBrand($event)"/>
                    <Dropdown v-model="val.value" :filter="true" class="dropdown-insert" :options="val.data" v-else-if="val.type == 'ARRAY'" optionLabel="name"/>
                    <label for="basic" v-if="val.nameKey == 'SUB_BRAND'|| val.nameKey == 'SUB BRAND'|| val.nameKey == 'SUB-BRAND'" >{{val.nameKey}}</label>
                    <Dropdown v-model="val.value" :filter="true" class="dropdown-insert" :options="sub_brand" v-if="val.nameKey=='SUB_BRAND'|| val.nameKey == 'SUB BRAND'|| val.nameKey == 'SUB-BRAND'" optionLabel="name"/>
                    <InputText type="number" class="text-insert" min='0' v-model="val.value" v-if="val.data=='NUMBER'" pattern="^(\d+[0-9])"/>
                    <InputText type="text" class="text-insert" v-model="val.value" v-if="val.data=='STRING' || val.data=='LINK' || val.data=='TEXT'"/>
                    <Calendar v-model="val.value" class="calendar-insert" v-if="val.data=='DATE' && val.nameKey!='NGÀY BĂT ĐẦU' && minDateBegin ==null" dateFormat="dd/mm/yy" placeholder="dd/mm/yy"/>
                        <Calendar v-model="val.value" class="calendar-insert" v-if="val.data=='DATE' && val.nameKey=='NGÀY BĂT ĐẦU'" dateFormat="dd/mm/yy" placeholder="dd/mm/yy" @hide="onChangeDate(val.nameKey)"/>
                        <Calendar v-model="val.value" class="calendar-insert" v-if="val.data=='DATE' && val.nameKey!='NGÀY BĂT ĐẦU' && minDateBegin !=null" dateFormat="dd/mm/yy" placeholder="dd/mm/yy" :minDate="minDateBegin" :disabled="minDateBegin ==null ? true:false"/>
                        <Calendar v-model="val.value" class="calendar-insert" :timeOnly="true" hourFormat="24" v-if="val.data=='HOUR'" placeholder="00:00"/>
                </div>
            </div>
            <div class="tableplan" v-if="dataTablePlan !='' && dataTablePlan != null">
                <table>
                    <thead class="p-datatable-thead">
                        <th class="action-table">
                            <i class="pi pi-plus"></i>
                        </th>
                        <th v-for="(valHead, indexHead) in abstractDataTable" :key="indexHead">
                            {{valHead.key}}
                        </th>
                    </thead>
                    <tbody class="p-datatable-tbody">
                        <template v-for="(table, index) in tableTest">
                            <tr :key="index">
                                <td class="action-table" @click="toggle(index)">
                                    <img src="./../assets/arrow-right.svg" alt="">
                                </td>
                                <td v-for="(row,i) in table" :key="i">
                                    <Dropdown v-model="row.value" class="dropdown-insert" :options="row.data" v-if="row.type == 'ARRAY' && row.key =='CÔNG VIỆC*'" optionLabel="name" @change="onChangeJob($event, index)"/>
                                    <Dropdown v-model="row.value" class="dropdown-insert" :options="listTypesPlan[index]" v-if="Array.isArray(listTypes) && row.key=='PHÂN LOẠI'" optionLabel="name"/>
                                    <Dropdown v-model="row.value" class="dropdown-insert" :options="row.data" v-if="row.type == 'ARRAY' && row.key !='CÔNG VIỆC*' && row.key !='PHÂN LOẠI'" optionLabel="name"/>
                                    <InputText type="text" class="text-insert" v-model="row.value" v-if="row.type=='STRING' || row.type=='LINK' || row.type=='TEXT'"/>
                                    <InputText type="number" class="text-insert" min='0' v-model="row.value" v-if="row.type=='NUMBER'" pattern="^(\d+[0-9])"/>
                                </td>
                                <td class="action-table" @click="removeItem(index)"><i class="pi pi-trash" style="color:red;"></i></td>
                            </tr>
                            <!-- <tr :key="'btn-addPlan-' + index">
                                
                            </tr> -->
                            <!-- Show expand item -->
                            <tr v-if="opened.includes(index)" :key="'show-'+index">
                                <td :colspan="table.length + 1">
                                    <div class="p-field table-content">
                                        <!-- {{slotProps.data}} -->
                                        <table class="box-expand">
                                            <tr v-for="(slot,k) in table" :key="k.id">
                                            <th v-if="slot.key != '_id'">{{slot.key}}</th>
                                            <td v-if="typeof slot.value ==='object' && slot.value !=='null'">{{slot.value.name}}</td>
                                            <td v-else-if="slot.key != '_id'">{{slot.value}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody> 
                </table>
                <Button label="Add" icon="pi pi-plus" class="btn-add" iconPos="right" @click="addRowTable()"/>
            </div>
            <template #footer>
                <Button label="Huỷ" class="btn-insertForm-cancel" @click="closeModal()"/>
                <Button label="Tạo mới" class="btn-insertForm-save" @click="insertRecord('insert')" v-if="!showDataNull"/>
            </template>
        </Dialog>
    </div>
</template>
<script>
export default {
    name: 'InsertTask',
    props: ['isOpenFormInsertTask', 'formName', 'formPlan'],
    data () {
        return {
            listDataForm:[],
            listSub_brand:[],
            dataTablePlan:[],
            sub_brand:[],
            abstractDataTable: [],
            tableTest: [],
            listTypesPlan:[],
            opened: [],
            listTypes:[{
                name: 'Không có dữ liệu',
                code: 'Không có dữ liệu'
            }],
            selectedJob: null,
            openDialog: this.isOpenFormInsertTask,
            blockedPanel: false,
            isLoadingData: false,
            afterConvertData: null,
            minDateBegin: null,
            openInsertTask: false,
            showDataNull: false
        }
    },
    created(){
        this.getData()
    },
    methods: {
        getData(){
            this.listDataForm=[]
            this.listSub_brand=[]
            this.dataTablePlan=[]
            this.sub_brand=[]
            this.blockedPanel = true
            this.listDataForm = [] // reset list data form
            this.listSub_brand = [] // reset list sub_Brand form
            
            let bodyFormData = new FormData();
                bodyFormData.append('loginToken', localStorage.getItem('jwt'));
                bodyFormData.append('mode', "requestForm");
                bodyFormData.append('formName', this.formName);
            let self = this
                self.axios({
                method: "post",
                url: this.$urlAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(response => {
            let dataForm = response.data
            if (dataForm == '') {
                this.showDataNull = true 
            }
            // console.log('sss: ' + JSON.stringify(dataForm))
            this.dataTablePlan = null
            if (dataForm) {
                    for (let key in dataForm) {
                        let regexPlan = key.match('triển khai');
                        if (regexPlan) {
                            let keyPlan = regexPlan.input
                            this.dataTablePlan = response.data[keyPlan]
                            this.abstractTable(this.dataTablePlan, this.selectedRowTable[keyPlan])
                        }
                        let arr = {
                            data: '',
                            required: '',
                            nameKey: key,
                            type: ''
                        }
                        if (key.includes('*')) {
                            if (Array.isArray(dataForm[key])) {
                            let arrF = []
                            if (dataForm[key].length > 0) {
                                for (let i = 0; i< dataForm[key].length; i++) {
                                    let object = {
                                        name: dataForm[key][i],
                                        code: dataForm[key][i]
                                    }
                                    arrF.push(object)
                                }
                                arr = {
                                    data: arrF,
                                    required: 'true',
                                    nameKey: key,
                                    type:"ARRAY",
                                    value: {
                                        name: self.formPlan[key] ? self.formPlan[key] : '',
                                        code: self.formPlan[key] ? self.formPlan[key] : ''
                                    }
                                }
                            } else {
                                let object = {
                                    name: "Không có dữ liệu",
                                    code: "Không có dữ liệu"
                                }
                                arrF.push(object)
                                arr = {
                                    data: arrF,
                                    required: 'true',
                                    nameKey: key,
                                    type:"ARRAY",
                                    value: {
                                        name: self.formPlan[key] ? self.formPlan[key] : '',
                                        code: self.formPlan[key] ? self.formPlan[key] : ''
                                    }
                                }
                            }
                            } else {
                            arr = {
                                data: dataForm[key] ? dataForm[key] : 'STRING',
                                    required: 'true',
                                    nameKey: key,
                                    value: self.formPlan[key] ? self.formPlan[key] : ''
                                }  
                            } // End check required array
                        } else if (key == 'SUB_BRAND' || key == 'SUB BRAND' || key == 'SUB-BRAND') {
                            this.listSub_brand = dataForm[key] //Push object data to listSub_brand
                            let dataOfBrand = self.formPlan["BRAND*"] // get data of brand
                            let arrSubBrand = []
                            this.sub_brand = null
                            for (const keySub in this.listSub_brand) {  //loop all data subband
                                if (keySub == dataOfBrand) {
                                    for (let i = 0; i< this.listSub_brand[keySub].length; i++) {
                                    let object = {
                                        name: this.listSub_brand[keySub][i],
                                        code: this.listSub_brand[keySub][i]
                                    }
                                    arrSubBrand.push(object)
                                    }
                                }
                            }
                            this.sub_brand = arrSubBrand
                            // Set data sub brand if exist
                            arr = {
                            nameKey: key,
                                value: {
                                    name: self.formPlan[key] ? self.formPlan[key] : '',
                                    code: self.formPlan[key] ? self.formPlan[key] : ''
                                }
                            }  
                        } else {
                        if (Array.isArray(dataForm[key])) {
                        let arrF = []
                        if (dataForm[key].length > 0) {
                            for (let i = 0; i< dataForm[key].length; i++) {
                                let object = {
                                    name: dataForm[key][i],
                                    code: dataForm[key][i]
                                }
                                arrF.push(object)
                            }
                            arr = {
                                data: arrF,
                                required: 'false',
                                nameKey: key,
                                type:"ARRAY",
                                value: {
                                    name: self.formPlan[key] ? self.formPlan[key] : '',
                                    code: self.formPlan[key] ? self.formPlan[key] : ''
                                }
                            }
                        } else {
                            let object = {
                                name: "Không có dữ liệu",
                                code: "Không có dữ liệu"
                            }
                            arrF.push(object)
                            arr = {
                                data: arrF,
                                required: 'false',
                                nameKey: key,
                                type:"ARRAY",
                                value: {
                                    name: self.formPlan[key] ? self.formPlan[key] : '',
                                    code: self.formPlan[key] ? self.formPlan[key] : ''
                                }
                            }
                        }
                        } else {
                        arr = {
                            data: dataForm[key] ? dataForm[key] : 'STRING',
                                required: 'false',
                                nameKey: key,
                                value:self.formPlan[key] ? self.formPlan[key] : ''
                            }  
                        }
                        }
                    this.listDataForm.push(arr)
                    }
                } // End if lôp  dataform
            }).catch(err => 
                this.$toast.add({
                    severity:'error', 
                    summary: 'Error Message', 
                    detail:'Err' + err, life: 3000
                })
            ).finally(() => this.blockedPanel = false)
            // Begin update
        },
        insertRecord(type){
            if (type == 'insert') {
                let dataInsertForm = []
                let ob = {}
                if (this.listDataForm) {
                    let check = this.checkObjectNotNull(this.listDataForm);
                    if (check == false) {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Error Message', 
                            detail:'Vui lòng nhập đầy dữ liệu vào form có dấu *:  ', life: 3000
                        })
                    } else {
                        let err = false
                        for (let index in this.listDataForm){
                            if(typeof this.listDataForm[index].value === 'object' && this.listDataForm[index].value !== null){
                                if (this.listDataForm[index].data == 'DATE') {
                                ob[this.listDataForm[index].nameKey] =this.formatDate(this.listDataForm[index].value, 'DATE')
                                } else if(this.listDataForm[index].data == 'HOUR'){
                                let time = this.formatDate(this.listDataForm[index].value, 'HOUR').slice(0,-3)
                                ob[this.listDataForm[index].nameKey] =time
                                } else {
                                ob[this.listDataForm[index].nameKey] =this.listDataForm[index].value.code
                                }
                            } else if (this.listDataForm[index].data == 'NUMBER') {
                                if (this.listDataForm[index].value != ''){
                                if (this.listDataForm[index].value < 0) {
                                    err = true
                                    this.$toast.add({
                                        severity:'error', 
                                        summary: 'Error Message', 
                                        detail:'Vui lòng nhập dữ liệu lớn hơn 0:  ', life: 3000
                                    })
                                } else {
                                    ob[this.listDataForm[index].nameKey] =this.listDataForm[index].value
                                }
                                } else {
                                ob[this.listDataForm[index].nameKey] = this.listDataForm[index].value
                                }
                            }
                            else {
                                ob[this.listDataForm[index].nameKey] =this.listDataForm[index].value
                            }
                        }// ENd for
                        ob['Kế hoạch triển khai'] = this.convertDataPlan()
                        ob['planId'] = this.formPlan._id
                        if (err == false) {
                            dataInsertForm.push(ob)
                            this.saveData(dataInsertForm)
                        }
                    } // ENd check isset required
                // console.log("data after check: " + JSON.stringify(dataInsertForm))
                }
            } else if (type == 'update'){
                let dataInsertForm = []
                let ob = {}
                if (this.listDataForm) {
                let check = this.checkObjectNotNull(this.listDataForm);
                if (check == false) {
                    this.$toast.add({
                    severity:'error', 
                    summary: 'Error Message', 
                    detail:'Vui lòng nhập đầy dữ liệu vào form có dấu *:  ', life: 3000
                    })
                } else {
                    let err = false
                    for (let index in this.listDataForm){
                    if(typeof this.listDataForm[index].value === 'object' && this.listDataForm[index].value !== null){
                        if (this.listDataForm[index].data == 'DATE') {
                        ob[this.listDataForm[index].nameKey] =this.formatDate(this.listDataForm[index].value, 'DATE')
                        } else if(this.listDataForm[index].data == 'HOUR'){
                        let time = this.formatDate(this.listDataForm[index].value, 'HOUR').slice(0,-3)
                        ob[this.listDataForm[index].nameKey] = time
                        // ob[this.listDataForm[index].nameKey] =this.formatDate(this.listDataForm[index].value, 'HOUR')
                        } else {
                        ob[this.listDataForm[index].nameKey] =this.listDataForm[index].value.code
                        }
                    } else if (this.listDataForm[index].data == 'NUMBER') {
                        if (this.listDataForm[index].value != '') {
                            if (this.listDataForm[index].value < 0){
                                err = true
                                this.$toast.add({
                                severity:'error', 
                                summary: 'Error Message', 
                                detail:'Vui lòng nhập dữ liệu lớn hơn 0:  ', life: 3000
                                })
                            } else {
                                    ob[this.listDataForm[index].nameKey] = this.listDataForm[index].value
                                }
                            } else {
                                ob[this.listDataForm[index].nameKey] = this.listDataForm[index].value
                            }
                        } else {
                            ob[this.listDataForm[index].nameKey] =this.listDataForm[index].value
                        }
                    }// ENd for
                    this.afterConvertData = this.convertDataPlan()
                    ob['Kế hoạch triển khai'] = this.afterConvertData
                    
                    if (err == false) {
                        dataInsertForm.push(ob)
                        this.updateData(dataInsertForm)
                    }
                } // ENd check isset required
                // console.log("data after check: " + JSON.stringify(dataInsertForm))
                }
            }
        },
        saveData(dataInsertForm){
            // Insert record
            this.blockedPanel = true
            let bodyFormData = new FormData();
                bodyFormData.append('loginToken', localStorage.getItem('jwt'));
                bodyFormData.append('mode', "insert");
                bodyFormData.append('collectionName', this.formName);
                bodyFormData.append('records', JSON.stringify(dataInsertForm));
            let self = this
            self.axios({
                method: "post",
                url: this.$urlAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(response => {
                dataInsertForm[0]._id = response.data._id
                this.blockedPanel = false
                if (response.data.status == "success"){
                    self.$toast.add({
                        severity:'success', 
                        summary: 'Success Message', 
                        detail:'Insert success', 
                        life: 3000
                    });

                    let dataToHome = {
                        data: dataInsertForm[0],
                        formNameData: this.formName
                    }
                    self.$emit('insertData', dataToHome)
                }
            }).catch(err => 
                {
                    self.$toast.add({
                        severity:'error', 
                        summary: 'Error Message', 
                        detail:'Err' + err, life: 3000
                    })
                }
            )
            .finally(() => {
                // this.products = [dataInsertForm[0], ...this.products]
                this.listDataForm = []
                this.closeModal()
            })
        },
        updateData(dataInsertForm){
            this.blockedPanel = true
            dataInsertForm[0].id=this.selectedRowTable._id
            let bodyFormData = new FormData();
                bodyFormData.append('loginToken', localStorage.getItem('jwt'));
                bodyFormData.append('mode', "update");
                bodyFormData.append('collectionName', this.formName);
                bodyFormData.append('records', JSON.stringify(dataInsertForm));
            let self = this
            self.axios({
                method: "post",
                url: this.$urlAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then(response => {
                this.blockedPanel = false
                if (response.data.status == "success"){
                let ob = {}
                for (const key in this.listDataForm) {
                    if(typeof this.listDataForm[key].value === 'object' && this.listDataForm[key].value !== null){
                    if (this.listDataForm[key].data == 'DATE') {
                        ob[this.listDataForm[key]['nameKey']] =this.formatDate(this.listDataForm[key].value, 'DATE')
                    } else if (this.listDataForm[key].data == 'HOUR'){
                        let time = this.formatDate(this.listDataForm[key].value, 'HOUR').slice(0,-3)
                        ob[this.listDataForm[key]['nameKey']] = time
                    } else {  
                        ob[this.listDataForm[key]['nameKey']] = this.listDataForm[key].value.name
                    }
                    } else {
                        ob[this.listDataForm[key]['nameKey']] = this.listDataForm[key].value
                    }
                }
                ob['_id'] = response.data._id
                ob['Kế hoạch triển khai'] = this.afterConvertData
                // back data to parent component
                self.$emit('updateData', ob) // update new data to datatable
                // console.log(ob)
                self.$toast.add({
                    severity:'success', 
                    summary: 'Success Message', 
                    detail:'Updated success', 
                    life: 3000
                });
                }
            }).catch(err => 
                self.$toast.add({
                    severity:'error', 
                    summary: 'Error Message', 
                    detail:'Err' + err, life: 3000
                })
            )
            .finally(() => {
                // self.blockedPanel = false
                self.closeModal()
            })
        },
        convertDataPlan(){
            if (this.tableTest.length > 0) {
                let arrData = []
                for (let i = 0; i < this.tableTest.length; i++){
                    let data = this.tableTest[i].data
                    if (data.length > 0) {
                        let ob = {}
                        for (let j = 0; j < data.length; j++){
                            ob['planId'] = data[j]['planId']
                            if (typeof data[j].value === 'object' && data[j].value !== null){
                                ob[data[j].key] = data[j].value.name
                            } else {
                                ob[data[j].key] = data[j].value
                            }
                        }
                        arrData.push(ob)
                    }
                }
                return arrData;
            }
        },
        abstractTable(dataPlan, itemtable){
            let data = dataPlan
            let ob = {
                key: '',
                type:'',
                value:'',
                required: '',
            }
            for (let key in data){
                //push data to types
                let regexType = key.match('PHÂN');
                if (regexType) {
                    this.types = {}
                    let dataType = regexType.input
                    this.types = data[dataType]
                }
                if (key.includes('*')) {
                    if(Array.isArray(data[key])){
                        if (data[key].length > 0){
                            let arrF = []
                            for (let i = 0; i < data[key].length; i++) {
                                let object = {
                                    name: data[key][i],
                                    code: data[key][i]
                                }
                                arrF.push(object)
                            } //Convert value to arr ob
                            ob = {
                                key: key,
                                type: "ARRAY",
                                data: arrF,
                                value: null,
                                required: true
                            }
                        } else {
                            let object = {
                                name: "Không có dữ liệu",
                                code: "Không có dữ liệu"
                            }
                            let arrF = [object]
                            ob = {
                                key: key,
                                type: "ARRAY",
                                data: arrF,
                                value: null,
                                required: true
                            }
                        }
                    } else { //End check is array
                        ob = {
                            key: key,
                            type: data[key],
                            value: '',
                            required: true
                        }
                    }
                    this.abstractDataTable.push(ob)
                } else { //else check required
                    if(Array.isArray(data[key])){
                        if (data[key].length > 0){
                            let arrF = []
                            for (let i = 0; i < data[key].length; i++) {
                                let object = {
                                    name: data[key][i],
                                    code: data[key][i]
                                }
                                arrF.push(object)
                            } //Convert value to arr ob
                            ob = {
                                key: key,
                                type: "ARRAY",
                                data: arrF,
                                value: null,
                                required: false
                            }
                        } else {
                            let object = {
                                name: "Không có dữ liệu",
                                code: "Không có dữ liệu"
                            }
                            let arrF = [object]
                            ob = {
                                key: key,
                                type: "ARRAY",
                                data:arrF,
                                value: null,
                                required: true
                            }
                        }
                    } else if (typeof data[key] ==='object' && data[key] !=='null') {
                        ob = {
                            data: [],
                            key: "PHÂN LOẠI",
                            value: [{
                                name: 'Không có dữ liệu',
                                code: 'Không có dữ liệu'
                            }],
                            type: "OBJECT",
                            required: true
                        }
                    } else {
                        ob = {
                            data: '',
                            key: key,
                            type: data[key],
                            value: '',
                            required: false
                        }
                    }
                    this.abstractDataTable.push(ob)
                }
            } // End for
            // Push data if exist 
            if (itemtable != null && itemtable.length > 0) {
                let self = this
                let abstract = self.abstractDataTable
                for (let index = 0; index < itemtable.length; index++) {
                    const element = itemtable[index];
                    let arr = []
                    let keyBrand = ''
                    Object.keys(element).forEach(ele => {
                        let ob = {}
                        for (let key in abstract) {
                            if (abstract[key].key == ele && abstract[key].key == 'CÔNG VIỆC*') {
                                keyBrand = element[ele]
                            }
                            if (abstract[key].key == ele) {
                                if (abstract[key].type == 'ARRAY') {
                                    ob = {
                                        data:abstract[key].data,
                                        key: abstract[key].key,
                                        value: {
                                            name: element[ele],
                                            code: element[ele]
                                        },
                                        type: abstract[key].type,
                                        required: abstract[key].required,
                                        planId: itemtable[index]['planId']
                                    }
                                    // console.log("abstract[key]: " +abstract[key].key)
                                    arr.push(ob)
                                } else if (abstract[key].type == 'OBJECT'){
                                    // Search 
                                    self.getUpdateDataPlan(keyBrand, index)
                                    ob = {
                                        data:abstract[key].data,
                                        key: abstract[key].key,
                                        value: {
                                            name: element[ele],
                                            code: element[ele]
                                        },
                                        type: abstract[key].type,
                                        required: abstract[key].required,
                                        planId: itemtable[index]['planId']
                                    }
                                    arr.push(ob)
                                } else {
                                    ob = {
                                        data:abstract[key].data,
                                        key: abstract[key].key,
                                        value: element[ele],
                                        type: abstract[key].type,
                                        required: abstract[key].required,
                                        planId: itemtable[index]['planId']
                                    }
                                    arr.push(ob)
                                }
                            }
                        }
                    })
                    self.tableTest.push(arr)
                }
            }
        },
        // Onchange date. 
        //if date begin none, date End none,
        // if date begin exist data, date End >= date begin
        onChangeDate(selectedKey){
            // let today = new Date();
            for (let index = 0; index < this.listDataForm.length; index++) {
                const element = this.listDataForm[index];
                let key = 'NGÀY KẾT THÚC'
                if (element.nameKey == key) {
                    element.value ='' 
                }
                if (selectedKey == element.nameKey) {
                    if (element.value != '') {
                        this.minDateBegin = element.value
                    }
                }
            }
        },
        // Support function
        onChangeBrand(event){
        if (event) {
            this.isShowSubBrand = true
            let arrSubBrand = []
            this.sub_brand = null
            for (const key in this.listSub_brand) {
                if (key == event.value.name) {
                    for (let i = 0; i< this.listSub_brand[key].length; i++) {
                    let object = {
                        name: this.listSub_brand[key][i],
                        code: this.listSub_brand[key][i]
                    }
                    arrSubBrand.push(object)
                    }
                }
                }
                this.sub_brand = arrSubBrand
            }
        }, //end check onChange brand
        checkObjectNotNull(obj){
            for (var key in obj) {
                if (obj[key].required == 'true' && obj[key].value == "")
                    return false;
            }
            return true;
        }, //end check checkObjectNotNull
        // Close current modal equal push to parent
        closeModal(){
            this.openDialog = false
            this.showDataNull = false
            this.$emit('openInsertTaskModal', this.openDialog)
        },
        onChangeJob(event, indexRow){
            if (event){
                this.listTypes = []
                let data = []
                data = this.types[event.value.name]
                if (data.length > 0) {
                    let ob = {}
                    for (let index = 0; index < data.length; index++) {
                        if (data[index] != ''){
                            ob = {
                                name: data[index],
                                code: data[index]
                            }
                            if(!this.listTypes.some(name => name.name === data[index])){
                                this.listTypes.push(ob)
                            }
                        } else {
                            ob = {
                                name: 'Không có dữ liệu',
                                code: 'Không có dữ liệu'
                            }
                            this.listTypes.push(ob)
                        }
                    }
                } else {
                    let ob = {
                        name: 'Không có dữ liệu',
                        code: 'Không có dữ liệu'
                    }
                    this.listTypes.push(ob)
                }
                // Push Object data
                this.listTypesPlan[indexRow] = this.listTypes
            } //End check exist event 
        },
        getUpdateDataPlan(nameBrand, indexRow){
            if (nameBrand){
                this.listTypes = []
                let data = []
                data = this.types[nameBrand]
                if (data.length > 0) {
                    let ob = {}
                    for (let index = 0; index < data.length; index++) {
                        if (data[index] != ''){
                            ob = {
                                name: data[index],
                                code: data[index]
                            }
                            if(!this.listTypes.some(name => name.name === data[index])){
                                this.listTypes.push(ob)
                            }
                        } else {
                            ob = {
                                name: 'Không có dữ liệu',
                                code: 'Không có dữ liệu'
                            }
                            this.listTypes.push(ob)
                        }
                    }
                } else {
                    let ob = {
                        name: 'Không có dữ liệu',
                        code: 'Không có dữ liệu'
                    }
                    this.listTypes.push(ob)
                }
                // Push Object data
                this.listTypesPlan[indexRow] = this.listTypes
            } //End check exist event 
        },
        addRowTable(){
            let data = this.abstractDataTable
            let ob = {}
            let d = []
            for (let i in data) {
                // console.log('data[i]: ' + JSON.stringify(data[i]))
                ob = {
                    data:data[i].data,
                    key: data[i].key,
                    value: data[i].value = '',
                    type: data[i].type,
                    required: data[i].required,
                }
                d.push(ob)
            }
            //Set list default 
            this.listTypesPlan.push([{
                name: 'Không có dữ liệu',
                code: 'Không có dữ liệu'
            }])
            d.hiddenClass = false
            //add row table
            let pushBtnInsertTask = {
                data: d,
                hiddenClass: true
            }
            this.tableTest.push(pushBtnInsertTask)
        },
        toggle(id){
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        },
        formInsertTask(indexRow){
            this.openInsertTask = true
            let data = this.tableTest[indexRow]
            let word = "CÔNG";
            let newRegex = new RegExp(word)
            Object.keys(data).forEach(d => {
                let string = data[d].key
                let matchRegex = newRegex.test(string)
                if (matchRegex){
                    this.selectedJob = data[d].value.name
                }
            })
        },
        removeItem(indexRow){
            this.tableTest.splice(indexRow, 1)
        },
        formatDate(dateString, type){
            if (type == 'HOUR'){
                let date = new Date(dateString);
                // let dateLocal = date.toLocaleTimeString().split()
                return date.toLocaleTimeString();
            } else if (type == 'DATE') {
                const date = new Date(dateString);
                return date.toLocaleDateString('en-GB');
            }
        },
    }
}
</script>
<style scoped>

table{
    width: 100%;
    border-collapse: collapse;
    width: max-content;
}
table thead{
    text-align: left;
}
table .action-table{
    width: 5%;
    text-align: center;
    cursor: pointer;
}
table tbody tr{
    color: #212529;
    transition: box-shadow 0.15s;
    outline-color: rgba(38, 143, 255, 0.5);
}
table thead th,
table tbody tr td{
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 1px;
    padding: 0.5rem 0.5rem;
}
.btn-add{
    margin-top: 10px;
    margin-right: 10px;
    float: left;
}
.hidden{
    display: none;
}
.activeClass{
    display: block;
}
.box-expand{
    width: 100%;
}
.box-expand th{
    width: 10%;
}
.box-expand td{
    width: 90%;
}
.tableplan .dropdown-insert{
    width: 100%;
}
.hiddenButton{
    display: none;
}
</style>