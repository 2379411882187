import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
      path: '*',
      // component: Login,
      component: () => import(/* webpackChunkName: "NotFoundPage" */"../views/NotFoundPage.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.requiresAuth){
    if (localStorage.getItem("jwt") === null && localStorage.getItem("secret") === null && localStorage.getItem("username") === null ) {
        next({name: 'Login'})
    } else {
      next()
    }
  } else {
      next()
  }
})

export default router
