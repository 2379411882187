import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import BlockUI from 'primevue/blockui';
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Tooltip from 'primevue/tooltip';
import SplitButton from 'primevue/splitbutton';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import AutoComplete from 'primevue/autocomplete';
import Sidebar from 'primevue/sidebar';
import Listbox from 'primevue/listbox';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';
import ToggleButton from 'primevue/togglebutton';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

Vue.use(ToastService);
Vue.use(PrimeVue);
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(ConfirmationService);

Vue.component('AutoComplete', AutoComplete);
Vue.component('Dialog', Dialog);
Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('ColumnGroup', ColumnGroup);
Vue.component('InputText', InputText);
Vue.component('Calendar', Calendar);
Vue.component('Password', Password);
Vue.component('Button', Button);
Vue.component('Checkbox', Checkbox);
Vue.component('ToggleButton', ToggleButton);
Vue.component('Toast', Toast);
Vue.component('InputNumber', InputNumber);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.component('Row', Row);
Vue.component('Tooltip', Tooltip);
Vue.component('SplitButton', SplitButton);
Vue.component('Dropdown', Dropdown);
Vue.component('Sidebar', Sidebar);
Vue.component('Listbox', Listbox);
Vue.component('ConfirmDialog', ConfirmDialog);
Vue.component('BlockUI', BlockUI);
// setting global variable
Vue.prototype.$urlAPI = 'https://webhooks.mongodb-realm.com/api/client/v2.0/app/marketingcalendar-pszkl/service/SyncData/incoming_webhook/wh1?secret=irP2J8TwB3caCHe'
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
